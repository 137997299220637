import React from 'react'
import _ from 'lodash'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { PROGRAM_TYPE } from 'mednet-util/src/constants/sponsorship'
import { programTypesConfig } from 'pharmacy/src/program/configs'
import ProgramListItem from 'pharmacy/src/program/programListItem'
import { Header1 } from 'pharmacy/src/typography'

import classes from './programsList.scss'

function ProgramsList({ flags }) {
  // TODO: Remove this code once feature flag is deprecated and feature is fully deployed
  const filteredProgramTypes = _.keys(programTypesConfig).filter(
    (programType) => {
      // Exclude CME_MODULE if the flag is false
      return (
        programType !== PROGRAM_TYPE.CME_MODULE || flags.enableCmeCollection
      )
    }
  )
  return (
    <div>
      <div className={classes.header}>
        <Header1>Programs</Header1>
      </div>
      <div>
        {filteredProgramTypes.map((programType) => (
          <div key={programType} className={classes.listItem}>
            <ProgramListItem
              programType={programType}
              trackingProps={{ location: 'home_page_programs_list' }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default withLDConsumer()(ProgramsList)
