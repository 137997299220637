import React, { useMemo, useEffect, useRef, useCallback, useState } from 'react'
import * as yup from 'yup'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import Recaptcha from 'react-google-recaptcha'
import { FastField, Formik } from 'formik'
import _, { isEmpty } from 'lodash'
import ReactPlayer from 'react-player/vimeo'
import { Helmet } from 'react-helmet'
import { event as gaEvent } from 'react-ga'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { FlexRow, FlexRowCell } from 'pharmacy/src/display/content'
import { TextInput } from 'pharmacy/src/input/textInput'
import { Button } from 'pharmacy/src/input/button'
import { ProximaNova } from 'pharmacy/src/typography'
import { ProximaNovaSelect } from 'pharmacy/src/input/select'

import {
  getMixpanelReferrerProperties,
  mixpanel,
} from 'mednet-util/src/tracking'

import {
  fetchUserByInviteCode,
  FETCH_USER_BY_INVITE_CODE,
  FETCH_USER_WITH_PERMISSIONS_PUBLIC,
} from 'mednet-cns/src/reducers/user'
import {
  validateCampaignExists,
  VALIDATE_CAMPAIGN_EXISTS,
} from 'mednet-cns/src/reducers/sponsorship'
import {
  getSpecialties,
  getSpecialtiesRequest,
  fetchSpecialties,
} from 'mednet-cns/src/reducers/specialty'
import { getRequest } from 'mednet-cns/src/api/v1'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { openModal } from 'mednet-cns/src/reducers/modal'

import { REGISTRATION_SPECIALTY_MODAL } from 'mednet-util/src/constants/modal'

import { validateUniqueRegistrationEmail } from 'mednet-cns/src/reducers/registration'

import { PageTitle } from 'components/pageTitle'
import { TitledSection } from 'components/titledSection'
import { SectionHeader } from 'components/titledSection/sectionHeader'
import { HighlightsBar } from 'components/highlightsBar'
import { LandingPageContent } from 'components/landingPageContent'

import { SpecialtyModal } from './specialtyModal'
import css from './registration.scss'

const schema = yup.object().shape({
  RegistrationForm: yup.object({
    email: yup
      .string()
      .email('Invalid email')
      .max(128, 'Too long')
      .required('Required'),
    password: yup
      .string()
      .min(4, 'Too short')
      .max(128, 'tooLong')
      .required('Required'),
    verifyPassword: yup.string().when(['invite_code', 'campaign_id'], {
      is: (invite_code, campaign_id) =>
        isEmpty(invite_code) && isEmpty(campaign_id),
      then: yup
        .string()
        .required('Required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    }),
    recaptcha: yup.string().when(['invite_code', 'campaign_id'], {
      is: (invite_code, campaign_id) =>
        process.env.CAPTCHA_ENABLED &&
        isEmpty(invite_code) &&
        isEmpty(campaign_id),
      then: yup.string().required('It is required to pass the reCAPTCHA test'),
    }),
  }),
  Profile: yup.object({
    firstname: yup.string().max(50, 'Too long').required('Required'),
    lastname: yup.string().max(50, 'Too long').required('Required'),
    invite_code: yup.string().max(255),
    specialty_id: yup.string().required('Required'),
  }),
})

const SPECIALTY_PREFIX = 'specialty-'
const SUPER_SPECIALTY_PREFIX = 'superSpecialty-'

const getValidationTag = (fieldName, touched, errors) => {
  const path = fieldName.split(/[[\]]+/).filter((subPath) => subPath.length)
  let isFieldTouched = touched
  let fieldErrors = errors

  path.forEach((subPath) => {
    isFieldTouched = isFieldTouched ? isFieldTouched[subPath] : undefined
    fieldErrors = fieldErrors ? fieldErrors[subPath] : undefined
  })
  return isFieldTouched && fieldErrors ? fieldErrors : undefined
}

const TextInputField = ({ name, touched, errors, ...restProps }) => (
  <FastField name={name}>
    {({ field }) => (
      <TextInput
        {...field}
        {...restProps}
        typeStyle="proximaNovaPrimary"
        showClear={false}
        onChangeHandlesEvent
        validationTag={getValidationTag(field.name, touched, errors)}
      />
    )}
  </FastField>
)

const SpecialtySelectField = ({
  name,
  options,
  touched,
  errors,
  ...restProps
}) => {
  return (
    <div className={css.specialtySelectFieldContainer}>
      <FastField name={name}>
        {({ field, form }) => (
          <ProximaNovaSelect
            {...restProps}
            name={name}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.optionId}
            placeholder="Specialty"
            options={options}
            onChange={(option) => {
              form.setFieldValue(field.name, option.optionId)
            }}
            value={options.find(
              (specialty) => specialty.optionId === field.value
            )}
            onBlur={field.onBlur}
            isSearchable
            formInput
          />
        )}
      </FastField>
      <div className={css.specialtySelectFieldError}>
        {getValidationTag(name, touched, errors)}
      </div>
    </div>
  )
}

const RecaptchaVerificationField = ({
  recaptchaKey,
  name,
  touched,
  errors,
}) => {
  if (!process.env.CAPTCHA_ENABLED) {
    return null
  }

  return (
    <FastField name={name}>
      {({ field, form }) => (
        <div className={css.recaptchaContainer}>
          <div className={css.recaptcha}>
            <Recaptcha
              sitekey={recaptchaKey}
              onChange={(value) => {
                form.setFieldValue(field.name, value)
              }}
            />
          </div>

          {getValidationTag(name, touched, errors) && (
            <div className={css.recaptchaRequiredMessage}>
              {getValidationTag(name, touched, errors)}
            </div>
          )}
        </div>
      )}
    </FastField>
  )
}

const SubmitButton = ({ isSubmitting }) => (
  <button className={css.submitButton} type="submit" disabled={isSubmitting}>
    <Button type="dark_blue" className={css.button} isLoading={isSubmitting}>
      <ProximaNova.Text2 className={css.buttonText}>Register</ProximaNova.Text2>
    </Button>
  </button>
)

const handleRegisterFormSubmit = ({
  event,
  values,
  setSubmitting,
  validateForm,
  touched,
  setTouched,
  setEmailExists,
  openSpecialtyModal,
  dispatch,
}) => {
  event.preventDefault()
  setSubmitting(true)

  // We need to trigger form validation manually on submit as we are using the normal html form not the Form from formik
  // We can use await for the promise and prevent only when needed instead of prevent and re-enable
  validateForm().then((errors) => {
    if (isEmpty(errors)) {
      dispatch(
        validateUniqueRegistrationEmail(
          values.RegistrationForm.email,
          (res) => {
            if (res.exists) {
              setSubmitting(false)
              setEmailExists(true)
            } else {
              if (
                values.Profile?.specialty_id?.startsWith(SUPER_SPECIALTY_PREFIX)
              ) {
                openSpecialtyModal((specialtyId) => {
                  event.target.elements['Profile[specialty_id]'].value =
                    specialtyId
                  event.target.submit()
                })
              } else if (
                values.Profile?.specialty_id?.startsWith(SPECIALTY_PREFIX)
              ) {
                event.target.elements['Profile[specialty_id]'].value = Number(
                  values.Profile.specialty_id.replace(SPECIALTY_PREFIX, '')
                )
                event.target.submit()
              }
            }
          }
        )
      )
    } else {
      setSubmitting(false)
      setTouched({ ...touched, ...errors })
    }
  })
}

const RegisterForm = (props) => {
  const {
    isSubmitting,
    touched,
    errors,
    hidden,
    recaptchaKey,
    values,
    validateForm,
    setSubmitting,
    setTouched,
    specialties,
    isCentered,
    invitedUser,
    setFieldValue,
  } = props

  const dispatch = useDispatch()

  const [emailExists, setEmailExists] = useState(false)

  // ensure distinct id is set to invited user id, which can happen after initial page load
  useEffect(() => {
    if (invitedUser?.userId) {
      setFieldValue('distinct_id', invitedUser?.userId)
    }
  }, [setFieldValue, invitedUser?.userId])

  const superSpecialtiesMap = useMemo(() => {
    return _.keyBy(
      _.values(specialties)
        .map((specialty) => specialty.superSpecialty)
        .filter(Boolean),
      'superSpecialtyId'
    )
  }, [specialties])

  const specialtiesOptions = useMemo(() => {
    return _.uniqBy(
      _.values(specialties ?? {}).map((specialty) => {
        return {
          optionId: specialty.superSpecialty
            ? `superSpecialty-${specialty.superSpecialty.superSpecialtyId}`
            : `specialty-${specialty.specialtyId}`,
          label: specialty.superSpecialty
            ? specialty.superSpecialty.superSpecialty
            : specialty.specialty,
        }
      }),
      'optionId'
    )
  }, [specialties])

  useEffect(() => {
    setEmailExists(false)
  }, [values.RegistrationForm?.email])

  const openSpecialtyModal = useCallback(
    (onSubmit) => {
      if (!values.Profile?.specialty_id?.startsWith(SUPER_SPECIALTY_PREFIX)) {
        return
      }

      const selectedSuperSpecialtyId = Number(
        values.Profile?.specialty_id.replace(SUPER_SPECIALTY_PREFIX, '')
      )

      dispatch(
        openModal(REGISTRATION_SPECIALTY_MODAL.modalId, {
          superSpecialty: superSpecialtiesMap[selectedSuperSpecialtyId],
          specialties: _.values(specialties),
          defaultSpecialtyId: invitedUser?.specialtyId,
          onSubmit: (selectedSpecialtyId) => {
            if (selectedSpecialtyId) {
              onSubmit(selectedSpecialtyId)
            }
          },
          onCancel: () => {
            setSubmitting(false)
          },
        })
      )
    },
    [
      dispatch,
      specialties,
      values.Profile?.specialty_id,
      superSpecialtiesMap,
      invitedUser?.specialtyId,
    ]
  )

  const handleSubmit = (event) => {
    handleRegisterFormSubmit({
      event,
      values,
      setSubmitting,
      validateForm,
      touched,
      setTouched,
      setEmailExists,
      openSpecialtyModal,
      dispatch,
    })
  }

  const registerFormClasses = classNames(css.registerForm, {
    [css.leftAlignedRegisterForm]: !isCentered,
  })

  return (
    <form
      className={registerFormClasses}
      hidden={hidden}
      action="/user/registration/"
      method="post"
      onSubmit={handleSubmit}
    >
      <TextInputField
        name="RegistrationForm[email]"
        placeholder="Email"
        className={css.containerWithMarginBottom}
        touched={touched}
        errors={errors}
      />
      {emailExists && (
        <div className={css.emailExistsMessage}>
          This user&apos;s email address already exists.
        </div>
      )}
      <TextInputField
        name="RegistrationForm[password]"
        placeholder="Create Password"
        touched={touched}
        errors={errors}
        isPassword
      />
      <div className={css.fieldHint}>(Minimum 4 characters)</div>

      {!values.Profile.invite_code && !values.Profile.campaign_id && (
        <TextInputField
          name="RegistrationForm[verifyPassword]"
          placeholder="Retype Password"
          className={css.containerWithMarginBottom}
          touched={touched}
          errors={errors}
          isPassword
        />
      )}

      <div className={css.formRow}>
        <div
          className={classNames(css.containerWithFlexGrow, css.firstNameField)}
        >
          <TextInputField
            name="Profile[firstname]"
            placeholder="First Name"
            className={css.containerWithMarginBottom}
            touched={touched}
            errors={errors}
          />
        </div>
        <div className={css.containerWithFlexGrow}>
          <TextInputField
            name="Profile[lastname]"
            placeholder="Last Name"
            className={css.containerWithMarginBottom}
            touched={touched}
            errors={errors}
          />
        </div>
      </div>

      <SpecialtySelectField
        name="Profile[specialty_id]"
        options={specialtiesOptions}
        touched={touched}
        errors={errors}
      />
      <div className={css.fieldHint}>
        theMednet is currently available to limited specialties
      </div>

      {!values.Profile.invite_code && !values.Profile.campaign_id && (
        <RecaptchaVerificationField
          name="RegistrationForm[recaptcha]"
          recaptchaKey={recaptchaKey}
          touched={touched}
          errors={errors}
        />
      )}

      {values.Profile.campaign_id && (
        <FastField
          type="hidden"
          name="Profile[campaign_id]"
          value={values.Profile.campaign_id}
        />
      )}

      {values.Profile.invite_code && (
        <FastField
          type="hidden"
          name="Profile[invite_code]"
          value={values.Profile.invite_code}
        />
      )}

      {Object.entries(values.Job).map(([jobFieldName, jobFieldValue]) => (
        <FastField
          key={`Job[${jobFieldName}]`}
          type="hidden"
          name={`Job[${jobFieldName}]`}
          value={jobFieldValue}
        />
      ))}

      {values.distinct_id && (
        <FastField
          key="distinct_id"
          type="hidden"
          name="distinct_id"
          value={values.distinct_id}
        />
      )}

      <SubmitButton isSubmitting={isSubmitting} />
    </form>
  )
}

const getInitialRegisterFormValues = (
  invitedUser,
  specialtiesMap,
  validatedInviteCode,
  validatedCampaignId
) => ({
  RegistrationForm: {
    email: invitedUser?.emailAddress ?? '',
    password: '',
    verifyPassword: '',
    recaptcha: '',
    // This is duplicate data, it exists under Profile.
    // We need to add invite_code & campaign_id as siblings of verifyPassword & recaptcha to
    // make conditional validation on these two fields https://github.com/jquense/yup/issues/935
    invite_code: validatedInviteCode,
    campaign_id: validatedCampaignId,
  },
  Profile: {
    firstname: invitedUser?.firstName ?? '',
    lastname: invitedUser?.lastName ?? '',
    invite_code: validatedInviteCode,
    campaign_id: validatedCampaignId,
    specialty_id: invitedUser
      ? specialtiesMap[invitedUser.specialtyId]?.superSpecialty
        ? `superSpecialty-${
            specialtiesMap[invitedUser.specialtyId].superSpecialty
              .superSpecialtyId
          }`
        : `specialty-${invitedUser.specialtyId}`
      : undefined,
  },
  Job: {
    name_id: '-1',
    user_id: '-1',
    type: 'X',
    active: '0',
  },
  // https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelget_distinct_id
  distinct_id: mixpanel.get_distinct_id(),
})

const isLoading = ({
  inviteCode,
  campaignId,
  validatedInviteCode,
  validatedCampaignId,
  invitedUserRequest,
  campaignRequest,
  specialtiesRequest,
  userDataRequest,
}) =>
  (inviteCode && !invitedUserRequest.isLoaded) ||
  ((campaignId || campaignId === 0) && !campaignRequest.isLoaded) ||
  !specialtiesRequest.isLoaded ||
  (!validatedInviteCode && !validatedCampaignId && !userDataRequest.isLoaded)

const RegistrationSection = (props) => {
  const {
    invalidRecaptchaResponse,
    specialties,
    recaptchaKey,
    isCentered,
    invitedUser,
    validatedInviteCode,
    validatedCampaignId,
    whenDisplay,
  } = props

  const getInitialValues = () =>
    getInitialRegisterFormValues(
      invitedUser,
      specialties,
      validatedInviteCode,
      validatedCampaignId
    )

  return (
    <div
      className={
        whenDisplay === 'small'
          ? css.displayWhenSmallScreen
          : css.displayWhenLargeScreen
      }
    >
      <TitledSection
        header2="Register for Free"
        headerUnderlined
        contentLeftAligned={!isCentered}
        headerLeftAligned={!isCentered}
      >
        {invalidRecaptchaResponse ? (
          <div className={css.failureMessage}>
            Something went wrong. Please try again.
          </div>
        ) : isLoading(props) ? (
          <div
            className={classNames(css.LoaderContainer, {
              [css.leftAlignedLoaderConatiner]: !isCentered,
            })}
          >
            <StarLoader isVerticalMargin />
          </div>
        ) : (
          <Formik initialValues={getInitialValues()} validationSchema={schema}>
            {(props) => (
              <RegisterForm
                {...props}
                specialties={specialties}
                recaptchaKey={recaptchaKey}
                isCentered={isCentered}
                invitedUser={invitedUser}
              />
            )}
          </Formik>
        )}
      </TitledSection>
    </div>
  )
}

const VideoHeader = () => (
  <div className={css.videoHeader}>
    Join the discussion on <span className={css.blueText}>theMednet:</span> a
    physician-only platform for expert answers to clinical questions
  </div>
)

const MainHeader = () => (
  <div className={css.header}>
    Join the discussion on
    <span className={css.blueText}> theMednet: </span>a physician-only platform
    for expert answers to clinical questions
  </div>
)

const makeSelectRegister = () =>
  createSelector(
    (state) => state.user.users,
    (state) => state.sponsorship.validatedCampaigns,
    (state) => getSpecialties(state),
    (state) => state.user.data,
    (state, inviteCode) =>
      getRequest(state, makeRequestName(FETCH_USER_BY_INVITE_CODE, inviteCode)),
    (state, _, campaignId) =>
      getRequest(state, makeRequestName(VALIDATE_CAMPAIGN_EXISTS, campaignId)),
    (state) => getSpecialtiesRequest(state),
    (state) => getRequest(state, FETCH_USER_WITH_PERMISSIONS_PUBLIC),
    (_, inviteCode) => inviteCode,
    (_0, _1, campaignId) => campaignId,
    (
      users,
      validatedCampaigns,
      specialties,
      userData,
      invitedUserRequest,
      campaignRequest,
      specialtiesRequest,
      userDataRequest,
      inviteCode,
      campaignId
    ) => {
      let invitedUser = undefined
      let validatedInviteCode = undefined
      let validatedCampaignId = undefined
      let activeSpecialties = undefined
      let recaptchaKey = undefined

      if (invitedUserRequest.isLoaded) {
        invitedUser = Object.values(users).find(
          (user) => user.inviteCode === inviteCode
        )

        if (invitedUser) {
          validatedInviteCode = inviteCode
        }
      }

      if (
        campaignRequest.isLoaded &&
        validatedCampaigns &&
        validatedCampaigns.includes(campaignId)
      ) {
        validatedCampaignId = campaignId
      }

      if (specialtiesRequest.isLoaded) {
        activeSpecialties = Object.fromEntries(
          Object.entries(specialties).filter(
            ([_, specialty]) => specialty.active
          )
        )
      }

      if (userDataRequest.isLoaded) {
        recaptchaKey = userData.recaptchaKey
      }

      return {
        invitedUserRequest,
        campaignRequest,
        specialtiesRequest,
        userDataRequest,
        invitedUser,
        validatedInviteCode,
        validatedCampaignId,
        specialties: activeSpecialties,
        recaptchaKey,
      }
    }
  )

export const Registration = (props) => {
  const params = new URLSearchParams(props.location.search)
  const inviteCode = params.get('invitecode')
  const campaignId = params.get('utm_campaign')
  const invalidRecaptchaResponse = params.get('invalid_recaptcha')
  const registrationVideoUrl = 'https://vimeo.com/703282444'
  const isInviteOrCampaign = inviteCode || campaignId

  const { direct_referrer_label } = getMixpanelReferrerProperties()
  const trackingParams = useRef({
    referrer_source: params.get('src') ?? direct_referrer_label,
    referrer_source_path: params.get('src_path'),
    referrer_source_item: params.get('src_item'),
    invite_code: inviteCode,
    campaign_id: campaignId,
  })

  const selectRegister = useMemo(makeSelectRegister, [])

  const state = useSelector((state) =>
    selectRegister(state, inviteCode, campaignId)
  )

  const {
    invitedUserRequest,
    campaignRequest,
    specialtiesRequest,
    userDataRequest,
  } = state

  const dispatch = useDispatch()

  const invitedUserId = state.invitedUser?.userId
  const inviteDoneLoading =
    !inviteCode || (!userDataRequest.isLoading && !invitedUserRequest.isLoading)

  useEffect(() => {
    if (process.env.GOOGLE_ANALYTICS_ID) {
      gaEvent({
        category: 'Registration',
        action: 'VisitedRegistrationPage',
      })
    }
  }, [])

  useEffect(() => {
    if (inviteDoneLoading) {
      if (invitedUserId) {
        mixpanel.identify(invitedUserId)
      }
      mixpanel.track(
        'registration_page',
        _.omitBy(trackingParams.current, _.isNil)
      )
    }
  }, [invitedUserId, inviteDoneLoading])

  useEffect(() => {
    // if no parameter for invite code provided, don't try to fetch user
    if (
      inviteCode &&
      !invitedUserRequest.isLoaded &&
      !invitedUserRequest.isLoading
    ) {
      dispatch(fetchUserByInviteCode(inviteCode))
    }

    // if no parameter for campaign provided, don't try to validate
    if (
      (campaignId || campaignId === 0) &&
      !campaignRequest.isLoaded &&
      !campaignRequest.isLoading
    ) {
      dispatch(validateCampaignExists(campaignId))
    }

    if (!specialtiesRequest.isLoaded && !specialtiesRequest.isLoading) {
      dispatch(fetchSpecialties())
    }
  })

  return (
    <LandingPageContent>
      <Helmet>
        <link rel="canonical" href="https://www.themednet.org/register" />
      </Helmet>
      <PageTitle>Register</PageTitle>

      {!isInviteOrCampaign && <SectionHeader header1={<MainHeader />} />}

      <FlexRow size="large" verticalMargins={isInviteOrCampaign}>
        <FlexRowCell className={css.containerWithFlexGrow}>
          <React.Fragment>
            <RegistrationSection
              inviteCode={inviteCode}
              campaignId={campaignId}
              invalidRecaptchaResponse={invalidRecaptchaResponse}
              {...state}
              isCentered={!isInviteOrCampaign}
              whenDisplay="large"
            />
            <RegistrationSection
              inviteCode={inviteCode}
              campaignId={campaignId}
              invalidRecaptchaResponse={invalidRecaptchaResponse}
              {...state}
              isCentered
              whenDisplay="small"
            />
          </React.Fragment>
        </FlexRowCell>

        {isInviteOrCampaign && (
          <FlexRowCell className={css.containerWithFlexGrow}>
            <TitledSection header1={<VideoHeader />}>
              <ReactPlayer
                url={registrationVideoUrl}
                controls
                className={css.videoPlayer}
                muted
                config={{
                  vimeo: {
                    playerOptions: {
                      autoplay: true,
                      texttrack: 'en',
                    },
                  },
                }}
              />
            </TitledSection>
          </FlexRowCell>
        )}
      </FlexRow>

      <HighlightsBar
        className={css.HighlightsBar}
        header={
          <div>
            Hear from leading <span className={css.boldText}>experts</span> from
            over 500 institutions
          </div>
        }
      />
      <SpecialtyModal />
    </LandingPageContent>
  )
}
