import React, { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'

import {
  Subtitle2,
  Link1,
  Link2,
  Subtitle3,
  Header3,
} from 'pharmacy/src/typography'
import { UserImage } from 'pharmacy/src/user/userImage'
import { Button } from 'pharmacy/src/input/button'
import { Link } from 'pharmacy/src/navigation/link'
import { Icon } from 'pharmacy/src/display/icon'

import { formatName } from 'mednet-util/src/string'
import { isUserPermittedTo, withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'
import { getEventPath } from 'mednet-util/src/poly'
import { mixpanel } from 'mednet-util/src/tracking'
import { CME_PAGE_VISIT_SOURCE } from 'mednet-util/src/constants/cme'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { getRequest } from 'mednet-cns/src/api/v1'
import {
  FETCH_USER_WITH_PERMISSIONS,
  fetchUserStats,
} from 'mednet-cns/src/reducers/user'
import {
  useCmeAndMocAvailabilityCheck,
  useTrackCmePageVisit,
} from 'mednet-cns/src/hooks/cme'

import css from './profileCard.scss'

const ModeratorPageLink = withPermissions(authItems.moderator)((props) => {
  const { numOpenModeratorQuestions } = props
  const hasOpenQuestions = numOpenModeratorQuestions > 0
  const TextComponent = hasOpenQuestions ? Link2 : Link1

  return (
    <div className={css.buttonContainer}>
      <TextComponent className={css.button}>
        <Button
          type="text"
          icon="user-md"
          external
          pathname="/question/moderatorpage"
        >
          Moderator Page
          {hasOpenQuestions && ` (${numOpenModeratorQuestions})`}
        </Button>
      </TextComponent>
    </div>
  )
})

const TumorBoardDashboardLink = ({
  isTumorBoardUser,
  doesUserHavePermission,
}) => {
  if (
    !isTumorBoardUser &&
    !doesUserHavePermission(authItems.viewTumorBoardDashboard)
  ) {
    return null
  }

  return (
    <div className={css.buttonContainer}>
      <Link1 className={css.button}>
        <Button
          type="text"
          icon="university"
          external
          pathname="/tumorboard/dashboard"
        >
          Tumor Board Dashboard
        </Button>
      </Link1>
    </div>
  )
}

const CmeDashboardLink = () => {
  const { isCmeOrMocAvailableForUser, isMocEnabledForUser: shouldDisplayMoc } =
    useCmeAndMocAvailabilityCheck()
  const trackCmePageVisit = useTrackCmePageVisit()
  const [isCmeInfoMessageOpen, setCmeInfoMessageStatus] = useState(false)
  const cmeInfoMessage = useRef()

  const removeDocumentEventListeners = useCallback(() => {
    if (typeof document !== 'undefined') {
      document.body.removeEventListener('touchstart', onWindowClick)
      document.body.removeEventListener('click', onWindowClick)
    }
  }, [typeof document === 'undefined' ? null : document])

  const onWindowClick = useCallback(
    (event) => {
      if (_.includes(getEventPath(event), cmeInfoMessage.current)) {
        return
      }

      removeDocumentEventListeners()
      setCmeInfoMessageStatus(false)
    },
    [removeDocumentEventListeners, setCmeInfoMessageStatus]
  )

  const handleCmeInfoIconClick = useCallback(
    (event) => {
      event.stopPropagation()

      if (isCmeInfoMessageOpen) {
        setCmeInfoMessageStatus(false)
      } else if (typeof document !== 'undefined') {
        document.body.addEventListener('touchstart', onWindowClick)
        document.body.addEventListener('click', onWindowClick)
        setCmeInfoMessageStatus(true)
      }
    },
    [
      setCmeInfoMessageStatus,
      onWindowClick,
      typeof document === 'undefined' ? null : document,
    ]
  )

  const handleCmeLinkClick = useCallback(() => {
    trackCmePageVisit(CME_PAGE_VISIT_SOURCE.SOURCE_PROFILE)
    mixpanel.track('viewed_redeem_cme_form_page', {
      source: CME_PAGE_VISIT_SOURCE.SOURCE_PROFILE,
    })
  }, [trackCmePageVisit])

  // On unmount clear window listeners
  useEffect(() => {
    return () => removeDocumentEventListeners
  }, [])

  if (!isCmeOrMocAvailableForUser) {
    return null
  }

  return (
    <div className={css.buttonContainer}>
      <Link1 className={css.button}>
        <Button
          type="text"
          icon="award"
          pathname="/cme"
          onClick={handleCmeLinkClick}
        >
          Redeem CME {shouldDisplayMoc && '& MOC'}
        </Button>
        <div className={css.infoIconContainer} onClick={handleCmeInfoIconClick}>
          <div className={css.infoIconInnerI} />
          <Icon
            prefix="fa"
            icon="info-circle"
            className={css.infoIcon}
            size="lg"
          />
        </div>
        {isCmeInfoMessageOpen && (
          <div className={css.cmeInfoMessage} ref={cmeInfoMessage}>
            <div className={css.cmeInfoMessageArrow} />
            You can now earn CME credit for internet point-of-care activities on
            theMednet
          </div>
        )}
      </Link1>
    </div>
  )
}

class ProfileCard extends React.Component {
  componentDidMount() {
    this.props.fetchUserStats()
  }

  renderButtons() {
    const { numOpenModeratorQuestions } = this.props.statsData
    const { isTumorBoardUser } = this.props.userData

    return (
      <div className={css.buttonsContainer}>
        <div className={css.buttonContainer}>
          <Link1 className={css.button}>
            <Button type="text" icon="cog" external pathname="/user/user/view">
              Settings
            </Button>
          </Link1>
        </div>
        <div className={css.buttonContainer}>
          <Link1 className={css.button}>
            <Button
              type="text"
              icon="envelope"
              external
              pathname="/message/inbox"
            >
              Mail
            </Button>
          </Link1>
        </div>
        <div className={css.buttonContainer}>
          <Link1 className={css.button}>
            <Button
              type="text"
              icon="chart-bar"
              external
              pathname="/answer/stats"
            >
              Stats
            </Button>
          </Link1>
        </div>
        <CmeDashboardLink />

        <ModeratorPageLink
          numOpenModeratorQuestions={numOpenModeratorQuestions}
        />
        <TumorBoardDashboardLink
          isTumorBoardUser={isTumorBoardUser}
          doesUserHavePermission={this.props.doesUserHavePermission}
        />
      </div>
    )
  }

  renderLoading() {
    return (
      <div className={css.card}>
        <UserImage isLoading className={css.loadingImage} />
        <div className={css.loadingName} />
        <div className={css.loadingTitle} />
        {this.renderButtons()}
      </div>
    )
  }

  render() {
    const { isLoaded, userData, statsData } = this.props
    const {
      userId,
      imageVersion,
      firstName,
      middleName,
      lastName,
      title,
      institutionName,
    } = userData
    const { questionsCreated, answersCreated, numFollowers } = statsData

    if (!isLoaded) {
      return this.renderLoading()
    }

    return (
      <div className={css.card}>
        <div>
          <UserImage
            className={css.image}
            userId={userId}
            imageVersion={imageVersion}
          />
          <Header3 className={css.name}>
            <Link
              pathname={`/user/user/view/id/${userId}`}
              className={css.nameLink}
              external
            >
              {formatName(firstName, middleName, lastName)}
            </Link>
          </Header3>
          {title && <Subtitle2 className={css.title}>{title}</Subtitle2>}
          {institutionName && (
            <Subtitle2 className={css.institution}>{institutionName}</Subtitle2>
          )}
          {this.renderButtons()}
          {!_.isEmpty(statsData) && (
            <div className={css.stats}>
              {numFollowers > 0 && (
                <Link
                  external
                  pathname={`/following/followers/${userId}`}
                  className={css.statsLink}
                >
                  <Subtitle3>{numFollowers} Followers</Subtitle3>
                </Link>
              )}
              {questionsCreated > 0 && (
                <Link
                  external
                  pathname="/question/stats"
                  className={css.statsLink}
                >
                  <Subtitle3>{questionsCreated} Questions Asked</Subtitle3>
                </Link>
              )}
              {answersCreated > 0 && (
                <Link
                  external
                  pathname="/answer/stats"
                  className={css.statsLink}
                >
                  <Subtitle3>{answersCreated} Answers Posted</Subtitle3>
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)

  return {
    userData: state.user.data,
    statsData: state.user.stats || {},
    isLoaded: userRequest.isLoaded,
    doesUserHavePermission: (requestedPermission) =>
      isUserPermittedTo(state.user.permissions, requestedPermission),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserStats: () => dispatch(fetchUserStats()),
  }
}

export default withLDConsumer()(
  compose(
    withPermissions(authItems.editProfile),
    connect(mapStateToProps, mapDispatchToProps)
  )(ProfileCard)
)
