import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Icon } from 'pharmacy/src/display/icon'
import { Body1 } from 'pharmacy/src/typography'

import { makeRequestName } from 'mednet-cns/src/reducers/request'
import {
  toggleUserSubspecialty,
  TOGGLE_USER_SUBSPECIALTY,
  getToggleUserSubspecialtyRequestId,
} from 'mednet-cns/src/reducers/subspecialty'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import css from './toggleUserSubspecialtyButton.scss'

class ToggleUserSubspecialtyButton extends React.Component {
  render() {
    const {
      subspecialty,
      toggleUserSubspecialty,
      currentUserId,
      isLoading,
      className,
    } = this.props

    const starIcon = [
      subspecialty?.isAssignedToCurrentUser ? 'fas' : 'far',
      'star',
    ]
    const containerClasses = classNames(css.container, className)

    return (
      <div
        className={containerClasses}
        onClick={() =>
          toggleUserSubspecialty(
            currentUserId,
            subspecialty.isAssignedToCurrentUser ? 'remove' : 'add'
          )
        }
      >
        {!isLoading && <Icon icon={starIcon} className={css.followIcon} />}
        {isLoading && (
          <div className={css.loaderContainer}>
            <StarLoader size="small" isVerticalMargin={false} />
          </div>
        )}

        <Body1 className={css.subspecialtyLabel}>
          {subspecialty?.subspecialty}
        </Body1>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const subspecialties = state.subspecialty?.subspecialties ?? {}
  const subspecialty = subspecialties[ownProps.subspecialtyId]
  const currentUserId = state.user.data?.userId
  const toggleRequest =
    _.get(
      state.request.requests,
      makeRequestName(
        TOGGLE_USER_SUBSPECIALTY,
        getToggleUserSubspecialtyRequestId(
          subspecialty?.subspecialtyId,
          currentUserId
        )
      )
    ) || {}

  return {
    subspecialty,
    currentUserId,
    isLoading: toggleRequest.isLoading,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleUserSubspecialty: (userId, action) =>
      dispatch(toggleUserSubspecialty(ownProps.subspecialtyId, userId, action)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToggleUserSubspecialtyButton)
