import _ from 'lodash'
import qs from 'qs'
import { makeURL } from 'mednet-util/src/router'
import { takeLatest, all } from 'redux-saga/effects'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from '../cns-util/reducer'

import { makePOSTHeadersFromParams } from '../api/v1'

import { makeRequestName } from './request'

export const FETCH_AVAILABLE = 'subspecialty/FETCH_AVAILABLE'
export const FETCH_SPECIALTY_SUBSPECIALTIES =
  'subspecialty/FETCH_SPECIALTY_SUBSPECIALTIES'
export const FETCH_SUBSPECIALTIES = 'subspecialty/FETCH_SUBSPECIALTIES'
export const TOGGLE_USER_SUBSPECIALTY = 'subspecialty/TOGGLE_USER_SUBSPECIALTY'

export function fetchAvailable(callback) {
  return {
    type: FETCH_AVAILABLE,
    callback,
  }
}

export function fetchSpecialtySubspecialties(
  specialtyId,
  subresources,
  callback
) {
  return {
    type: FETCH_SPECIALTY_SUBSPECIALTIES,
    requestId: getFetchSpecialtySubspecialtiesRequestId(
      specialtyId,
      subresources
    ),
    specialtyId,
    subresources,
    callback,
  }
}

export function fetchSubspecialties(callback) {
  return {
    type: FETCH_SUBSPECIALTIES,
    callback,
  }
}

export function toggleUserSubspecialty(
  subspecialtyId,
  userId,
  action, // add or remove
  callback
) {
  return {
    type: TOGGLE_USER_SUBSPECIALTY,
    requestId: getToggleUserSubspecialtyRequestId(subspecialtyId, userId),
    userId,
    subspecialtyId,
    action,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_AVAILABLE,
    'subspecialty/getAvailableJSON'
  )

  yield makeFetchEffect(takeLatest, FETCH_SPECIALTY_SUBSPECIALTIES, (action) =>
    makeURL(
      `specialty/getSubspecialtiesJSON/${action.specialtyId}?${qs.stringify(
        { subresources: action.subresources },
        { arrayFormat: 'indices' }
      )}`
    )
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_SUBSPECIALTIES,
    'subspecialty/getJSON'
  )

  yield makeFetchEffect(
    takeLatest,
    TOGGLE_USER_SUBSPECIALTY,
    (action) =>
      makeURL(
        `subspecialty/toggleUserSubspecialtyJSON/subspecialtyId/${action.subspecialtyId}`
      ),
    (action) =>
      makePOSTHeadersFromParams({
        userId: action.userId,
        subspecialtyId: action.subspecialtyId,
        action: action.action,
      })
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  subspecialties: {},
}

export function reducer(state = initialState, action, rootState = {}) {
  switch (action.type) {
    case receiveAction(FETCH_AVAILABLE): {
      return receiveReducer(state, action, () => ({
        available: action.response.available.map(
          (subspecialty) => subspecialty.subspecialtyId
        ),
        subspecialties: {
          ...state.subspecialties,
          ..._.keyBy(action.response.available, 'subspecialtyId'),
        },
      }))
    }

    case receiveAction(FETCH_SPECIALTY_SUBSPECIALTIES): {
      return receiveReducer(state, action, () => {
        const set = action.response[action.specialtyId]
        if (!_.isArray(set)) return state
        return {
          subspecialties: {
            ...state.subspecialties,
            ...set.reduce((map, subspecialty) => {
              map[subspecialty.subspecialtyId] = subspecialty
              return map
            }, {}),
          },
        }
      })
    }

    case receiveAction(FETCH_SUBSPECIALTIES): {
      return receiveReducer(state, action, () => {
        const subspecialties = action.response.subspecialties
        if (!_.isArray(subspecialties)) return state
        return {
          subspecialties: {
            ...state.subspecialties,
            ...subspecialties.reduce((map, subspecialty) => {
              map[subspecialty.subspecialtyId] = subspecialty
              return map
            }, {}),
          },
        }
      })
    }

    case receiveAction(TOGGLE_USER_SUBSPECIALTY): {
      const currentUserId = rootState.user.data?.userId
      return receiveReducer(state, action, () => {
        const success = action.response.success
        if (!success) return state
        return {
          subspecialties: {
            ...state.subspecialties,
            [action.subspecialtyId]: {
              ...state.subspecialties[action.subspecialtyId],
              // we yet don't have a property for other users subspecialties, values for other users may also
              // later exist under different root state userSubspecialty, and not exist here
              isAssignedToCurrentUser:
                action.action === 'add' && currentUserId === action.userId,
            },
          },
        }
      })
    }

    default:
      return state
  }
}

export function getSubSpecialties(state, specialtyId) {
  return !specialtyId
    ? _.values(state.subspecialty.subspecialties)
    : _.filter(
        state.subspecialty.subspecialties || [],
        (subspecialty) => subspecialty.specialtyId === specialtyId
      )
}

export function getAreSubSpecialtiesLoading(state, specialtyId) {
  return _.get(
    state.request.requests,
    makeRequestName(FETCH_SPECIALTY_SUBSPECIALTIES),
    specialtyId
  ).isLoading
}

export function getFetchSpecialtySubspecialtiesRequestId(
  specialtyId,
  subresources
) {
  return `${specialtyId}${
    subresources ? `;subresources:${subresources.join(',')}` : ''
  }`
}

export function getToggleUserSubspecialtyRequestId(subspecialtyId, userId) {
  return `${subspecialtyId};userId:${userId}`
}
