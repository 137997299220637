import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'

import { MOC_STATUS } from 'mednet-util/src/constants/cme'
import { useCmeAndMocAvailablilityById } from 'mednet-cns/src/hooks/user'

import {
  fetchCmeConfig,
  fetchAvailableCmeActivites,
  fetchRedeemedCmeActivites,
  checkIfQuestionAvailableForCme,
  fetchCmeApplications,
  fetchCmeGoals,
  fetchCmeImpacts,
  fetchSponsorshipRedeemableAnswers,
  redeemCmeActivity,
  updateRedeemedCmeActivity,
  fetchCertificateDownloadLink,
  createCmeCertificate,
  fetchCmeCertificates,
  fetchCurrentYearCmeActivitiesCounts,
  fetchQuestionCmePreviewItems,
  setRecentCertificates,
  trackCmePageVisit,
  updateMocProfile,
  updateMocProfileById,
  shouldPromptForMoc,
  redeemMocActivities,
  fetchRedemptionRelatedQuestions,
  fetchRedeemedCountsForYear,
  fetchMocAvailableActivities,
  fetchRedeemedMocActivities,
} from 'mednet-cns/src/reducers/cme'
import { createDataHook } from 'mednet-cns/src/hook-factory'

import { useUser } from './user'

export const [useCmeConfig, useCmeConfigLazy] = createDataHook(
  fetchCmeConfig,
  createSelector(
    (state) => state.cme.cmeConfig,
    (cmeConfig) => cmeConfig || {}
  )
)

export const useCmeAndMocAvailabilityCheck = (userId = null) => {
  let cmeAndMocAvailability
  let request
  if (userId) {
    const [userData, userRequest] = useCmeAndMocAvailablilityById(userId)
    cmeAndMocAvailability = userData ?? {}
    request = userRequest
  } else {
    const [{ userData }, userRequest] = useUser()
    cmeAndMocAvailability = userData?.cmeAndMocAvailability ?? {}
    request = userRequest
  }

  const { cmeStatus, mocStatus = MOC_STATUS.MOC_DISABLED } =
    cmeAndMocAvailability

  return {
    isCmeOrMocAvailableForUser: cmeStatus,
    isCmeAndMocAvailabilityCheckLoading: request.isLoading,
    isCmeAndMocAvailabilityCheckLoaded: request.isLoaded,
    isMocEnabledForUser: parseInt(mocStatus) !== MOC_STATUS.MOC_DISABLED,
    isAbimMocEnabledForUser:
      (parseInt(mocStatus) & MOC_STATUS.MOC_ABIM_ENABLED) !== 0, // bitwise and
    isAbpMocEnabledForUser:
      (parseInt(mocStatus) & MOC_STATUS.MOC_ABP_ENABLED) !== 0, // bitwise and
  }
}

export const [useAvailableCmeActivities, useAvailableCmeActivitiesLazy] =
  createDataHook(
    fetchAvailableCmeActivites,
    createSelector(
      (state) => state.cme.availableCmeActivities,
      (availableCmeActivities) => availableCmeActivities
    )
  )

export const [useRedeemedCmeActivities, useRedeemedCmeActivitiesLazy] =
  createDataHook(
    fetchRedeemedCmeActivites,
    createSelector(
      (state) => state.cme.redeemedCmeActivities,
      (_state, { requestId }) => requestId,
      (redeemedCmeActivities, year) => redeemedCmeActivities[year] || []
    )
  )

export const [useRedeemedMocActivities, useRedeemedMocActivitiesLazy] =
  createDataHook(
    fetchRedeemedMocActivities,
    createSelector(
      (state) => state.cme.redeemedMocActivities,
      (_state, { requestId }) => requestId,
      (redeemedMocActivities, year) => redeemedMocActivities[year] || []
    )
  )

export const [useCmeGoals, useCmeGoalsLazy] = createDataHook(
  fetchCmeGoals,
  createSelector(
    (state) => state.cme.goals,
    (goals) => {
      return {
        goals,
      }
    }
  )
)

export const [useCmeApplications, useCmeApplicationsLazy] = createDataHook(
  fetchCmeApplications,
  createSelector(
    (state) => state.cme.applications,
    (applications) => {
      return {
        applications,
      }
    }
  )
)

export const [useCmeImpacts, useCmeImpactsLazy] = createDataHook(
  fetchCmeImpacts,
  createSelector(
    (state) => state.cme.impacts,
    (impacts) => {
      return {
        impacts,
      }
    }
  )
)

export const useRedeemCmeActivity = () => {
  const dispatch = useDispatch()

  return (data, activity, callback) =>
    dispatch(redeemCmeActivity(data, activity, callback))
}

export const useRedeemMocActivities = () => {
  const dispatch = useDispatch()

  return (data, callback) => dispatch(redeemMocActivities(data, callback))
}

export const [
  useQuestionCmeRedeemableCheck,
  useQuestionCmeRedeemableCheckLazy,
] = createDataHook(
  checkIfQuestionAvailableForCme,
  createSelector(
    (state) => state.cme.cmeRedeemableQuestionsIds,
    (state) => state.cme.mocRedeemableQuestionsIds,
    (_state, { requestId }) => requestId,
    (cmeRedeemableQuestionsIds, mocRedeemableQuestionsIds, questionId) => {
      return {
        isQuestionRedeemableForCme: questionId in cmeRedeemableQuestionsIds,
        isQuestionRedeemableForMoc: questionId in mocRedeemableQuestionsIds,
        questionActivityId:
          cmeRedeemableQuestionsIds[questionId] ||
          mocRedeemableQuestionsIds[questionId],
      }
    }
  )
)

export const [
  useSponsorshipCmeRedeemableAnswers,
  useSponsorshipCmeRedeemableAnswersLazy,
] = createDataHook(
  fetchSponsorshipRedeemableAnswers,
  createSelector(
    (state) => state.cme.cmeSponsorshipsActivities,
    (_state, { requestId }) => requestId,
    (cmeSponsorshipsActivities, sponsorshipId) =>
      cmeSponsorshipsActivities[sponsorshipId] || []
  )
)

export const useUpdateRedeemedCmeActivity = () => {
  const dispatch = useDispatch()

  return (data, redeemId, callback) =>
    dispatch(updateRedeemedCmeActivity(data, redeemId, callback))
}

export const [useCertificateDownloadUrl, useCertificateDownloadUrlLazy] =
  createDataHook(
    fetchCertificateDownloadLink,
    createSelector(
      (state) => state.cme.certificatesDownloadUrls,
      (_state, { requestId }) => requestId,
      (certificatesDownloadUrls, certificateId) =>
        certificatesDownloadUrls[certificateId] || ''
    )
  )

export const [useCmeActivitiesCount, useCmeActivitiesCountLazy] =
  createDataHook(
    fetchCurrentYearCmeActivitiesCounts,
    createSelector(
      (state) => state.cme.redeemedMaxCmeForCurrentYear,
      (state) => state.cme.availableCmeCount,
      (state) => state.cme.yearsRedeemedCmeCounts[new Date().getFullYear()],
      (redeemedMaxForCurrentYear, availableCmeCount, redeemedCmeCount) => {
        return {
          redeemedMaxForCurrentYear,
          availableCount: availableCmeCount || 0,
          redeemedCount: redeemedCmeCount || 0,
        }
      }
    )
  )

export const useCreateCmeCertificate = () => {
  const dispatch = useDispatch()

  return (formData, activitiesIds, callback) => {
    dispatch(createCmeCertificate(formData, activitiesIds, callback))
  }
}

export const [useCmeCertificates, useCmeCertificatesLazy] = createDataHook(
  fetchCmeCertificates,
  createSelector(
    (state) => state.cme.certificates,
    (_state, { requestId }) => requestId,
    (certificates, year) => certificates[year] || []
  )
)

export const [useQuestionCmePreview, useQuestionCmePreviewLazy] =
  createDataHook(
    fetchQuestionCmePreviewItems,
    createSelector(
      (state) => state.cme.questionsCmePreviewItems,
      (_state, { requestId }) => requestId,
      (questionsCmePreviewItems, questionId) =>
        questionsCmePreviewItems[questionId] || {}
    )
  )

export const useSetCmeRecentCertificates = () => {
  const dispatch = useDispatch()
  return (certificatesHashes) =>
    dispatch(setRecentCertificates(certificatesHashes))
}

export const useCmeRecentCertificates = () => {
  const selector = createSelector(
    (state) => state.cme.recentCertificates,
    (recentCertificates) => recentCertificates || []
  )

  return useSelector((state) => selector(state))
}

export const useTrackCmePageVisit = () => {
  const dispatch = useDispatch()

  return (source) => {
    dispatch(trackCmePageVisit(source))
  }
}

export const useUpdateMocProfile = () => {
  const dispatch = useDispatch()

  return (data, callback) => dispatch(updateMocProfile(data, callback))
}

export const useUpdateMocProfileById = (userId) => {
  const dispatch = useDispatch()
  return (data, callback) =>
    dispatch(updateMocProfileById(data, userId, callback))
}

export const [useShouldPromptForMoc, useShouldPromptForMocLazy] =
  createDataHook(
    shouldPromptForMoc,
    createSelector(
      (state) => state.cme.promptMocProfile,
      (promptMocProfile) => promptMocProfile
    )
  )

export const [
  useRedemptionRelatedQuestions,
  useRedemptionRelatedQuestionsLazy,
] = createDataHook(
  fetchRedemptionRelatedQuestions,
  createSelector(
    (state) => state.cme.redemptionRelatedQuestions,
    (redemptionRelatedQuestions) => redemptionRelatedQuestions
  )
)

export const [useRedeemedCounts, useRedeemedCountsLazy] = createDataHook(
  fetchRedeemedCountsForYear,
  createSelector(
    (state) => state.cme.yearsRedeemedCmeCounts,
    (state) => state.cme.yearsRedeemedMocCounts,
    (state) => state.cme.yearsCompletedCmeModuleCredits,
    (_state, { requestId }) => requestId,
    (
      yearsRedeemedCmeCounts,
      yearsRedeemedMocCounts,
      yearsCompletedCmeModuleCredits,
      year
    ) => {
      return {
        redeemedCmeCount: yearsRedeemedCmeCounts[year] || '',
        redeemedMocCount: yearsRedeemedMocCounts[year] || '',
        completedCmeModuleCredits: yearsCompletedCmeModuleCredits[year] || '',
      }
    }
  )
)

export const [useActivitiesAvailableForMoc, useActivitiesAvailableForMocLazy] =
  createDataHook(
    fetchMocAvailableActivities,
    createSelector(
      (state) => state.cme.activitiesAvailableForMoc,
      (state) => state.cme.redeemedMaxMocForCurrentYear,
      (activitiesAvailableForMoc, redeemedMaxMocForCurrentYear) => {
        return {
          mocAvailableActivities: activitiesAvailableForMoc || [],
          redeemedMaxForCurrentYear: redeemedMaxMocForCurrentYear,
        }
      }
    )
  )
