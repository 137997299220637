import React from 'react'

import { LandingPageContent } from 'components/landingPageContent'

import css from './aiGuidelines.scss'

const SECTIONS = [
  {
    title: 'Personal Expertise',
    items: [
      'Physicians on theMednet are expected to provide their own personal answers to questions, drawing from their professional knowledge and experience.',
      "Answers should reflect the physician's individual perspective and expertise, rather than a general or automated reply.",
    ],
  },
  {
    title: 'Use of AI',
    items: [
      'Answers that are generated by inputting a question from theMednet or similar clinical query into an AI search or chatbot are not suitable for theMednet and will be removed. AI-generated answers lack the depth of physician insight, making them less valuable to the community.',
      'Any assistance from AI resources should merely facilitate the expression of your own concepts and conclusions.  The most valuable part of your answer is the expertise you bring to the conversation. Therefore, AI tools should only be used the way you might use a scribe, assisting in organizing and articulating original ideas.',
      'Generating an answer from an AI tool and posting it on theMednet is strictly prohibited.',
    ],
  },
  {
    title: 'References',
    items: [
      'All references in answers or comments must be vetted to ensure they are reliable resources for the community.',
      'By including a reference in your answer, you confirm that you have personally reviewed it and deemed it credible and relevant to the topic at hand.',
    ],
  },
]

function AiGuidelinesSection({ title, items }) {
  return (
    <ul style={{ marginTop: 0, marginBottom: 10 }}>
      <li>
        <div className={css.sectionTitle}>{title}</div>
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <div>{item}</div>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  )
}

export default function AiGuidelinesPage() {
  return (
    <LandingPageContent>
      <div className={css.pageContainer}>
        <div className={css.innerPageContainer}>
          <div className={css.pageTitle}>AI Guidelines for Physicians</div>
          <div className={css.introductionContainer}>
            The following guidelines are designed to ensure the responsible use
            of artificial intelligence (AI) on theMednet, in order to maintain
            the integrity and accuracy of the answers provided.
          </div>

          {SECTIONS.map(({ title, items }) => (
            <AiGuidelinesSection key={title} title={title} items={items} />
          ))}

          <div className={css.footerContainer}>
            Content that violates these guidelines will be removed. Repeated
            violations may result in having privileges on theMednet modified or
            revoked. By adhering to these guidelines, we can ensure that
            theMednet remains a trusted source of medical knowledge for all
            physicians.
          </div>
        </div>
      </div>
    </LandingPageContent>
  )
}
