import React, { useMemo, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  useQuestionMetaLazy,
  useQuestionAnswersLazy,
} from 'mednet-cns/src/hooks/question'

import { SimpleSelect } from 'pharmacy/src/input/select'
import { TextInput } from 'pharmacy/src/input/textInput'
import { Button } from 'pharmacy/src/input/button'
import CenteredContent from 'pharmacy/src/display/content/centeredContent'
import { Header2, Header3 } from 'pharmacy/src/typography'
import { SimpleDropdownIndicator } from 'pharmacy/src/input/select/components'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { useServerScripts } from 'mednet-cns/src/hooks/campaign'
import { JOB_TYPE } from 'mednet-cns/src/reducers/systemJob'

import { createCampaignInviteQuestion } from 'mednet-cns/src/reducers/campaign'

import { QuestionTemplate, PollSelect, AnswersSelect } from './question'
import { ServerScriptsPage } from './serverScripts'
import { ImageUpload } from './imageUpload'

import * as css from './dynamic.scss'

const TemplatePreviewContainer = React.forwardRef(
  ({ children, className }, ref) => {
    return (
      <div className={className}>
        <div className="mceTmpl" ref={ref}>
          {children}
        </div>
      </div>
    )
  }
)
TemplatePreviewContainer.displayName = 'TemplatePreviewContainer'

const DynamicTemplatesPage = ({ innerRef, campaignType }) => {
  const templates = useMemo(() => {
    if (
      campaignType === JOB_TYPE.CAMPAIGN ||
      campaignType === JOB_TYPE.INVITE
    ) {
      return [
        {
          label: 'Question',
          value: 'question',
        },
        {
          label: 'Image Upload',
          value: 'imageUpload',
        },
        {
          label: 'Server scripts',
          value: 'scripts',
        },
      ]
    }
    return [
      {
        label: 'Server scripts',
        value: 'scripts',
      },
    ]
  }, [campaignType])

  const [templateType, setTemplateType] = useState(templates[0])
  const [questionId, setQuestionId] = useState()
  const [usedAnswers, setUsedAnswers] = useState([])
  const [poll, setPoll] = useState(false)
  const [pollQuestion, setPollQuestion] = useState(false)
  const [scriptName, setScriptName] = useState()
  const [imageUploadState, setImageUploadState] = useState({
    src: '',
    description: '',
    height: 0,
    width: 0,
  })

  const [inviteCampaign, setInviteCampaign] = useState(null)

  const [questionAnswers, answerRequestDetails, loadAnswers] =
    useQuestionAnswersLazy(questionId)
  const [questionMeta, metaRequestDetails, loadMeta] =
    useQuestionMetaLazy(questionId)
  const [serverScripts, serverScriptsRequestDetails] = useServerScripts()

  const isQuestionDataLoading =
    answerRequestDetails.isLoading || metaRequestDetails.isLoading
  const isQuestionDataLoaded =
    answerRequestDetails.isLoaded || metaRequestDetails.isLoaded

  const dispatch = useDispatch()

  const getQuestionData = useCallback(() => {
    loadAnswers()
    loadMeta()
    if (campaignType === JOB_TYPE.INVITE) {
      if (questionId) {
        dispatch(
          createCampaignInviteQuestion(questionId, (res) => {
            setInviteCampaign(res?.data)
          })
        )
      }
    }
  }, [questionId, campaignType])

  const renderTemplate = useCallback(() => {
    switch (templateType?.value) {
      case 'imageUpload':
        return (
          <TemplatePreviewContainer
            ref={innerRef}
            className={css.templateContainer}
          >
            <p>
              <img
                src={imageUploadState?.src}
                alt={imageUploadState?.description}
                width={imageUploadState?.width}
                height={imageUploadState?.height}
              />
            </p>
          </TemplatePreviewContainer>
        )
      case 'question':
        if (!isQuestionDataLoading && isQuestionDataLoaded) {
          return (
            <TemplatePreviewContainer
              ref={innerRef}
              className={css.templateContainer}
            >
              <QuestionTemplate
                campaignType={campaignType}
                inviteQuestionHash={inviteCampaign?.subject}
                inviteQuestionCampaignId={inviteCampaign?.id}
                question={questionMeta}
                answers={usedAnswers}
                poll={poll}
                pollQuestion={pollQuestion}
              />
            </TemplatePreviewContainer>
          )
        }
        return null
      case 'scripts':
        if (
          !serverScriptsRequestDetails.isLoading &&
          serverScriptsRequestDetails.isLoaded &&
          scriptName
        ) {
          return (
            <>
              <div>
                Email scripts are piece of code which is run just before email
                is sent. Example of the choosen email script is shown below.
                When saved, placeholder is put into the email body. To see how
                it looks like, please send a preview to yourself.
              </div>
              <div className={css.templateContainer}>
                <ServerScriptsPage name={scriptName} />
              </div>
              <TemplatePreviewContainer ref={innerRef} className={css.hidden}>
                %script:{scriptName}%
              </TemplatePreviewContainer>
            </>
          )
        }
        return null
      default:
        return null
    }
  }, [
    usedAnswers,
    templateType,
    poll,
    pollQuestion,
    questionId,
    isQuestionDataLoading,
    isQuestionDataLoaded,
    scriptName,
    serverScriptsRequestDetails.isLoading,
    serverScriptsRequestDetails.isLoaded,
    inviteCampaign,
    imageUploadState,
  ])

  const handleImagePreviewChange = (src, description, height, width) => {
    setImageUploadState({ src, description, height, width })
  }

  const renderTemplateOptions = useCallback(() => {
    if (isQuestionDataLoading || serverScriptsRequestDetails.isLoading) {
      return <StarLoader />
    }

    switch (templateType?.value) {
      case 'imageUpload':
        return (
          <div>
            <ImageUpload onChange={handleImagePreviewChange} />
          </div>
        )
      case 'question':
        return (
          <div className={css.settings}>
            <div className={css.question}>
              <TextInput
                showClear={false}
                className={css.input}
                type="none"
                value={questionId}
                onChange={setQuestionId}
                placeholder="Type question ID here"
              />
              <Button
                className={css.button}
                size="small"
                onClick={getQuestionData}
                isLoading={isQuestionDataLoading}
                isDisabled={isQuestionDataLoading || !questionId}
              >
                Load question
              </Button>
            </div>

            {!isQuestionDataLoading && isQuestionDataLoaded && (
              <>
                {Boolean(questionAnswers.length) && (
                  <AnswersSelect
                    answers={questionAnswers}
                    onAnswerSelect={setUsedAnswers}
                  />
                )}
                {!questionMeta.poll ? null : (
                  <PollSelect
                    poll={poll}
                    setPoll={() => setPoll((poll) => !poll)}
                    pollQuestion={pollQuestion}
                    setPollQuestion={() =>
                      setPollQuestion((pollQuestion) => !pollQuestion)
                    }
                  />
                )}
              </>
            )}
          </div>
        )
      case 'scripts':
        return (
          !serverScriptsRequestDetails.isLoading &&
          serverScriptsRequestDetails.isLoaded &&
          serverScripts.length && (
            <div className={css.templateTypeControls}>
              <Header3>Select script:</Header3>
              <SimpleSelect
                className={css.select}
                options={serverScripts.map((serverScript) => ({
                  value: serverScript,
                  label: serverScript,
                }))}
                onChange={(val) => setScriptName(val.value)}
                components={{
                  SingleValue: ({ children }) => <Header3>{children}</Header3>,
                  DropdownIndicator: SimpleDropdownIndicator,
                }}
              />
            </div>
          )
        )
      default:
        return null
    }
  }, [
    usedAnswers,
    templateType,
    poll,
    pollQuestion,
    questionId,
    serverScripts,
    isQuestionDataLoading,
    isQuestionDataLoaded,
    serverScriptsRequestDetails.isLoading,
    serverScriptsRequestDetails.isLoaded,
  ])

  return (
    <CenteredContent size="large">
      <div className={css.templateTypeControls}>
        <Header2>Template type:</Header2>
        <SimpleSelect
          className={css.select}
          options={templates}
          value={templateType}
          onChange={setTemplateType}
          components={{
            SingleValue: ({ children }) => <Header2>{children}</Header2>,
            DropdownIndicator: SimpleDropdownIndicator,
          }}
        />
      </div>
      {renderTemplate()}
      {renderTemplateOptions()}
    </CenteredContent>
  )
}

export default DynamicTemplatesPage
