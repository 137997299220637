import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'

import { Icon } from 'pharmacy/src/display/icon'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { CME_CERTIFICATE_TYPE_LABEL } from 'mednet-util/src/constants/cme'
import { mixpanel } from 'mednet-util/src/tracking'

import { useCertificateDownloadUrlLazy } from 'mednet-cns/src/hooks/cme'

import Table from 'components/tables/table'

import css from './cmeCertificatesTable.scss'

const CertificateDownloadButton = ({ certificateHash }) => {
  const [
    certificateDownloadUrl,
    certificateDownloadUrlRequest,
    loadCertificateDownloadUrl,
  ] = useCertificateDownloadUrlLazy(certificateHash)

  const [download, setDownload] = useState(false)

  const aRef = useRef()

  const handleClick = useCallback(() => {
    setDownload(true)
    mixpanel.track('Clicked Certificate Download Button', {
      hash: certificateHash,
    })
  }, [certificateHash, setDownload])

  useEffect(() => {
    if (download && certificateDownloadUrlRequest.isLoaded) {
      aRef.current.href = certificateDownloadUrl
      aRef.current.click()
      setDownload(false)
    } else if (download && !certificateDownloadUrlRequest.isLoading) {
      loadCertificateDownloadUrl()
    }
  }, [
    certificateDownloadUrlRequest.isLoaded,
    certificateDownloadUrlRequest.isLoading,
    download,
  ])

  return (
    <div className={css.downloadButtonContainer}>
      <a
        ref={aRef}
        onClick={handleClick}
        className={css.downloadLink}
        hidden={download}
      >
        <Icon icon="file-download" prefix="far" />
      </a>
      {download && (
        <div className={css.loaderConatiner}>
          <StarLoader size="small" isVerticalMargin={false} />
        </div>
      )}
    </div>
  )
}

export default function CmeCertificatesTable({
  certificates,
  loadingDataError,
  isLoading,
  bodyHeight: bodyHeight = 438,
  creditsPerActivity,
  recentCertificates,
}) {
  const glowRowsIds = useMemo(() => {
    return Object.fromEntries(
      certificates.map((certificate) => [
        certificate.id,
        recentCertificates.includes(certificate.hash),
      ])
    )
  }, [recentCertificates, certificates])

  const columns = useMemo(
    () => [
      {
        Header: <div>Created</div>,
        accessor: 'creationDate',
        sortable: true,
        sortType: (rowA, rowB) => {
          if (recentCertificates[rowA.id] && !recentCertificates[rowB.id]) {
            return 1
          }

          if (!recentCertificates[rowA.id] && recentCertificates[rowB.id]) {
            return -1
          }

          return moment(rowA.original.creationDate, 'YYYY-MM-DD').isAfter(
            moment(rowB.original.creationDate, 'YYYY-MM-DD')
          )
            ? 1
            : -1
        },
        headerClassName: css.dateTableCell,
        className: css.dateTableCell,
        Cell: ({ value }) => (
          <div className={css.cellContent}>
            {moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')}
          </div>
        ),
      },
      {
        Header: 'Report Name',
        headerClassName: css.reportNameCell,
        className: css.reportNameCell,
        Cell: ({ row }) => {
          const label =
            row.original.name ??
            `${CME_CERTIFICATE_TYPE_LABEL[row.original.type]} Report`
          const datesString = [row.original.startDate, row.original.endDate]
            .filter((x) => Boolean(x))
            .map((d) => moment(d, 'YYYY-MM-DD').format('MM/DD/YYYY'))
            .join(' - ')
          return (
            <div className={css.reportName}>
              <span className={css.reportType}>{label}</span>
              <span className={css.reportDates}>{datesString}</span>
            </div>
          )
        },
      },
      {
        Header: 'Credits',
        accessor: 'actvitiesCount',
        headerClassName: css.creditsCell,
        className: css.creditsCell,
        Cell: ({ value, row }) => {
          return (
            <div className={css.cellContent}>
              {row.original.cmeCredits ?? parseInt(value) * creditsPerActivity}
            </div>
          )
        },
      },
      {
        Header: <div className={css.downloadHeader}>Download</div>,
        accessor: 'hash',
        headerClassName: css.downloadCell,
        className: css.downloadCell,
        Cell: ({ value }) => {
          return <CertificateDownloadButton certificateHash={value} />
        },
      },
    ],
    [certificates]
  )

  return (
    <>
      <Table
        columns={columns}
        data={certificates}
        isLoading={isLoading}
        bodyHeight={bodyHeight}
        loadingDataError={loadingDataError}
        errorMessage="Couldn't load certificates. Please try again"
        emptyDataMessage="You have no certificates for the selected year and type"
        shadeOddRows
        withBorders
        itemSize={70}
        sortBy={[
          {
            id: 'creationDate',
            desc: true,
          },
        ]}
        removeFooter
        dynamicWidth
        glowRowsIds={glowRowsIds}
      />
    </>
  )
}
