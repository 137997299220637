import { PROGRAM_TYPE } from 'mednet-util/src/constants/sponsorship'

import JournalClubIcon from './icons/journalClubIcon'
import TargetedTopicIcon from './icons/targetedTopicIcon'
import MultiSpecialtySpotlightIcon from './icons/multiSpecialtySpotlightIcon'
import InvitedProfessorSeriesIcon from './icons/invitedProfessorSeries'
import AcademicRoundTableIcon from './icons/academicRoundTableIcon'
import CmeCertificateIcon from './icons/cmeCertificateIcon'

export const programTypesConfig = {
  [PROGRAM_TYPE.JOURNAL_CLUB]: {
    text: 'Journal Club',
    listText: 'Journal Clubs',
    bgColor: '#E0E8EB',
    textColor: '#262626',
    Icon: JournalClubIcon,
    iconProps: {
      style: {
        marginBottom: '3px',
      },
    },
    description:
      'Journal Clubs feature Q&A around specific paper(s), allowing physicians to ask experts and study authors questions related to the manuscript and learn more about applications to clinical practice.',
  },

  [PROGRAM_TYPE.TARGETED_TOPIC]: {
    text: 'Targeted Topic',
    listText: 'Targeted Topics',
    bgColor: '#E0E8EB',
    textColor: '#262626',
    Icon: TargetedTopicIcon,
    description:
      'Targeted topics feature Q&A around specific clinical themes, allowing physicians to ask invited experts questions relevant to the topic being discussed.',
  },
  [PROGRAM_TYPE.MULTI_SPECIALTY_SPOTLIGHT]: {
    text: 'Multi-Specialty Spotlight',
    listText: 'Multi-Specialty Spotlights',
    bgColor: '#E0E8EB',
    textColor: '#262626',
    Icon: MultiSpecialtySpotlightIcon,
    description:
      'Multi-Specialty Spotlights feature Q&A around a specific topic that has relevance to more than one specialty, and therefore allows physicians to interact with colleagues and experts outside their own specialty.',
  },
  [PROGRAM_TYPE.INVITED_PROFESSOR_SERIES]: {
    text: 'Invited Professor Series',
    listText: 'Invited Professor Series',
    bgColor: '#E0E8EB',
    textColor: '#262626',
    Icon: InvitedProfessorSeriesIcon,
    description:
      'The Invited Professor Series features educational content, such as a recorded lecture, from an invited expert and then features Q&A relevant to that content.',
  },
  [PROGRAM_TYPE.ACADEMIC_ROUND_TABLE]: {
    text: 'Academic Round Table',
    listText: 'Academic Round Tables',
    bgColor: '#E0E8EB',
    textColor: '#262626',
    Icon: AcademicRoundTableIcon,
    description:
      'Academic Round Tables offer an asynchronous platform for experts to engage in in-depth discussions on the latest research and emerging data, with a focus on translating new findings into practical clinical insights.',
  },
  [PROGRAM_TYPE.CME_MODULE]: {
    text: 'CME Collections',
    listText: 'CME Collections',
    bgColor: '#E0E8EB',
    textColor: '#262626',
    Icon: CmeCertificateIcon,
    description:
      'CME Collections provide a focused Q&A experience around key clinical topics or papers. Physicians can engage with experts, ask questions, and learn from both their responses and the experiences of peers. These activities provide an opportunity to earn additional CME credits and/or MOC points, beyond what is available through regular site activities.',
  },
}
