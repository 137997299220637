import React from 'react'

import { mixpanel } from 'mednet-util/src/tracking'
import { RouterTabs, TabButton } from 'pharmacy/src/navigation/tabs'
import { QuestionFeed } from 'pharmacy/src/display/feed'
import { UserInviteMixin } from 'pharmacy/src/display/feed/mixins/userInviteMixin'
import { QuestionCard } from 'pharmacy/src/question/questionCard'

import css from './home.scss'

class HomeFeed extends React.Component {
  static questionsFeedProps = {
    itemComponent: QuestionCard,
    trackViewedItems: true,
    highlightExpertAuthor: true,
    enableProgramHighlight: process.env.PROGRAMS_PAGES_RELEASED,
  }

  static tabs = [
    {
      header: <TabButton title="Most Recent" icon={['far', 'clock']} />,
      component: (
        <QuestionFeed
          {...HomeFeed.questionsFeedProps}
          feedName="recent"
          mixins={[
            {
              mixinComponent: UserInviteMixin,
              firstIndex: 10,
              repeatEvery: 20,
            },
          ]}
        />
      ),
    },
    {
      header: <TabButton title="Most Active" icon="chart-line" />,
      component: (
        <QuestionFeed {...HomeFeed.questionsFeedProps} feedName="active" />
      ),
    },
    {
      header: <TabButton title="Unanswered" icon="comment-slash" />,
      component: (
        <QuestionFeed {...HomeFeed.questionsFeedProps} feedName="unanswered" />
      ),
    },
    {
      header: <TabButton title="Saved" icon="bookmark" />,
      component: (
        <QuestionFeed {...HomeFeed.questionsFeedProps} feedName="starred" />
      ),
    },
  ]

  onTabChange = (activeTab) => {
    mixpanel.track('viewed_feed_tab', {
      feed_sort: HomeFeed.tabs[activeTab].component.props.feedName,
    })
  }

  render() {
    return (
      <RouterTabs
        onTabChange={this.onTabChange}
        tabs={HomeFeed.tabs}
        headerProps={{
          fullWidthTabs: true,
          underlined: true,
          headerTabProps: {
            tabButtonProps: {
              className: css.feedTabButton,
              activeClassName: css.activeFeedTabButton,
            },
          },
        }}
      />
    )
  }
}

export default HomeFeed
