export const SHARE_MODAL = {
  modalId: 'share_modal',
  screens: {
    share: 'share',
    finished: 'finished',
  },
}

export const POST_ANSWER_MODAL = {
  modalId: 'post_answer_modal',
  screens: {
    disclosurePrompt: 'disclosurePrompt',
    disclosureForm: 'disclosureForm',
    pollPrompt: 'pollPrompt',
  },
}

export const QUESTION_REQUEST_MODAL = {
  modalId: 'question_request_modal',
  screens: {
    confirmation: 'confirmation',
    requestType: 'requestType',
    moreAnswers: 'moreAnswers',
    updatedAnswers: 'updatedAnswers',
  },
}

export const ONBOARDING_MODAL = {
  modalId: 'onboarding_modal',
  screens: {
    intro: 'intro',
    customizeFeed: 'customizeFeed',
  },
}

export const QUESTION_SHORT_TITLE_MODAL = {
  modalId: 'question_short_title_modal',
  screens: {
    form: 'form',
  },
}

export const EMAIL_PREVIEW_MODAL = {
  modalId: 'email_preview_modal',
  screens: {
    digest: 'digest',
    subjects: 'subjects',
  },
}

export const SEND_PREVIEW_MODAL = {
  modalId: 'send_preview_modal',
  screens: {
    sendTo: 'sendTo',
  },
}

export const EXPERT_EMAIL_MODAL = {
  modalId: 'expert_email_modal',
  screens: {
    email: 'email',
    finished: 'finished',
  },
}

export const DELETE_COMMENT_MODAL = {
  modalId: 'delete_comment_modal',
}

export const MOVE_COMMENT_MODAL = {
  modalId: 'move_comment_modal',
}

export const DELETE_ANSWER_MODAL = {
  modalId: 'delete_answer_modal',
}

export const MOVE_ANSWER_MODAL = {
  modalId: 'move_answer_modal',
}

export const DELETE_DIGEST_UPDATE_MODAL = {
  modalId: 'delete_digest_update_modal',
}

export const DELETE_DIGEST_QUESTION_MODAL = {
  modalId: 'delete_digest_question_modal',
}

export const DELETE_DIGEST_MODAL = {
  modalId: 'delete_digest_modal',
}

export const ADD_DIGEST_UPDATE_MODAL = {
  modalId: 'add_digest_update_modal',
}

export const EMAIL_CONTACT_MODAL = {
  modalId: 'email_contact_modal',
  screens: {
    email: 'email',
    finished: 'finished',
  },
}

export const JOB_DASHBOARD_EDIT_LOCATION_MODAL = {
  modalId: 'job_dashboard_edit_location_modal',
  screens: {
    edit: 'edit',
    finished: 'finished',
  },
}

export const JOB_DASHBOARD_EDIT_INSTITUTION_MODAL = {
  modalId: 'job_dashboard_edit_institution_modal',
  screens: {
    edit: 'edit',
    finished: 'finished',
  },
}

export const JOB_DASHBOARD_EDIT_SUMMARY_MODAL = {
  modalId: 'job_dashboard_edit_summary_modal',
  screens: {
    edit: 'edit',
    finished: 'finished',
  },
}

export const JOB_DASHBOARD_EDIT_CV_MODAL = {
  modalId: 'job_dashboard_edit_cv_modal',
  screens: {
    edit: 'edit',
    finished: 'finished',
  },
}

export const SPONSORSHIP_CREATE_MODAL = {
  modalId: 'sponsorship_create_modal',
  screens: {
    create: 'create',
    finished: 'finished',
  },
}

export const SPONSORSHIP_UPDATE_MODAL = {
  modalId: 'sponsorship_update_modal',
  screens: {
    create: 'create',
    finished: 'finished',
  },
}

export const SPONSORSHIP_UPLOAD_PAPER_MODAL = {
  modalId: 'sponsorship_upload_paper_modal',
}

export const SPONSORSHIP_EDIT_PAPER_MODAL = {
  modalId: 'sponsorship_edit_paper_modal',
}

export const SPONSORSHIP_REMOVE_PAPER = {
  modalId: 'sponsorship_remove_paper',
}

export const SPONSORSHIP_ADD_QUESTION_MODAL = {
  modalId: 'sponsorship_add_question_modal',
}

export const SPONSORSHIP_CREATE_QUESTION_MODAL = {
  modalId: 'sponsorship_create_question_modal',
}

export const SPONSORSHIP_IMPORT_CURRICULUM_QUESIONS_MODAL = {
  modalId: 'sponsorship_import_curriculum_questions_modal',
}

export const SPONSORSHIP_PREVIEW_UPDATE_MODAL = {
  modalId: 'sponsorship_preview_update_modal',
  screens: {
    preview: 'preview',
  },
}

export const SPONSORSHIP_NOTIFICATIONS_MODAL = {
  modalId: 'SPONSORSHIP_NOTIFICATIONS_MODAL',
  screens: {
    preview: 'preview',
  },
}

export const SPONSORSHIP_REMOVE_QUESTION = {
  modalId: 'sponsorship_remove_question',
}

export const CAMPAIGN_REGISTER_MODAL = {
  modalId: 'campaign_register_modal',
  screens: {
    view: 'view',
  },
}

export const SPONSORSHIP_INJECT_QUESTION_MODAL = {
  modalId: 'sponsorship_inject_question_modal',
  screens: {
    inject: 'inject',
  },
}

export const DELETE_SPONSORSHIP_INJECTION_MODAL = {
  modalId: 'delete_sponsorship_injection_modal',
}

export const VIEW_BIO_MODAL = {
  modalId: 'view_bio_modal',
}

export const RECIPIENTS_MODAL = {
  modalId: 'recipients_modal',
  screens: {
    main: 'main',
  },
}

export const DYNAMIC_TEMPLATES_MODAL = {
  modalId: 'dynamic_templates_modal',
  screens: {
    main: 'main',
  },
}

export const SCHEDULE_CAMPAIGN_MODAL = {
  modalId: 'schedule_campaign_modal',
}

export const CAMPAIGN_ERRORS_MODAL = {
  modalId: 'campaign_errors_modal',
}

export const CAMPAIGN_SAVED_MODAL = {
  modalId: 'campaign_saved_modal',
}

export const SPONSORSHIP_CME_MODAL = {
  modalId: 'sposorship_cme_modal',
}

export const CME_CERTIFICATE_MODAL = {
  modalId: 'cme_certificate_modal',
}

export const MOC_PROFILE_MODAL = {
  modalId: 'moc_profile_modal',
}

export const MOC_ASSESSMENT_MODAL = {
  modalId: 'moc_assessment_modal',
}

export const SPLIT_DIGEST_COHORT_MODAL = {
  modalId: 'split_digest_cohort_modal',
}

export const REGISTRATION_SPECIALTY_MODAL = {
  modalId: 'registration_specialty_modal',
}
