import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { useLDClient } from 'launchdarkly-react-client-sdk'

export const useIsLDReadyForUser = () => {
  const selectData = useCallback(
    createSelector(
      (state) => (state.user?.data ? state.user.data.userId : undefined),
      (state) => (state.user ? state.user.launchDarklyStatus : undefined),
      (userId, launchDarklyStatus) => {
        return {
          userId,
          launchDarklyStatus,
        }
      }
    )
  )
  const { userId, launchDarklyStatus } = useSelector(selectData)

  const ldClient = useLDClient()
  // Check if LaunchDarkly client is initialized with current user context and ready
  return (
    Boolean(ldClient) &&
    launchDarklyStatus?.loaded &&
    ldClient?.getContext()?.key === userId?.toString()
  )
}
