import React, { useState } from 'react'

import CmeProgramHighlight from 'pharmacy/src/program/cmeProgramHighlight'

import classNames from 'classnames'

import css from './question.scss'

export default function CmeHighlightFooter({
  sponsorship,
  questionId,
  stubbed,
}) {
  const [visible, setVisible] = useState(true)

  return (
    <div
      className={classNames(css.cmeHighlightFooter, {
        [css.hiddenCmeHighlightFooter]: !visible,
      })}
    >
      <div className={css.leftColumnSpacer}></div>
      <div className={css.rightFeedContent}>
        <div className={css.middleColumn}>
          <div className={css.cmeHighlightFooterContent}>
            <CmeProgramHighlight
              sponsorship={sponsorship}
              trackingProps={{
                question_id: questionId,
                location: 'question_page_fixed_footer',
              }}
              stubbed={stubbed}
              onClose={() => setVisible(false)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
