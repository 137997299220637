export const PROGRAM_TYPE = {
  JOURNAL_CLUB: 'JOURNAL_CLUB',
  TARGETED_TOPIC: 'TARGETED_TOPIC',
  ACADEMIC_ROUND_TABLE: 'ACADEMIC_ROUND_TABLE',
  MULTI_SPECIALTY_SPOTLIGHT: 'MULTI_SPECIALTY_SPOTLIGHT',
  INVITED_PROFESSOR_SERIES: 'INVITED_PROFESSOR_SERIES',
  CME_MODULE: 'CME_MODULE',
}

export const PROGRAM_PUBLISH_STATUS = {
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
}

export const CME_MODULE_STATUS = {
  AVAILABLE_STATUS: 'AVAILABLE',
  HIDDEN_STATUS: 'HIDDEN',
}

export const USER_CME_MODULE_USER_STAGE = {
  INTRODUCTION: 'INTRODUCTION',
  PRE_EXAM: 'PRE_EXAM',
  QUESTION_REVIEW: 'QUESTION_REVIEW',
  POST_EXAM: 'POST_EXAM',
  REDEEM_CME: 'REDEEM_CME',
  COMPLETE: 'COMPLETE',
}
