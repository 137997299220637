import classNames from 'classnames'
import React from 'react'

import { createTabs } from './creators'

import css from './tabs.scss'

const HeaderContainer = ({ children, underlined }) => (
  <div
    className={classNames(css.headerContainer, {
      [css.underlinedHeader]: underlined,
    })}
  >
    {children}
  </div>
)

const TabContainer = ({ children, fullWidthTabs, tabsCount, className }) => {
  return (
    <div
      className={classNames(css.tab, className)}
      style={
        fullWidthTabs && {
          width: `${100 / tabsCount}%`,
          minWidth: 'fit-content',
        }
      }
    >
      {children}
    </div>
  )
}

export default createTabs({
  HeaderContainer,
  TabContainer,
  ComponentsContainer: 'div',
})
