import React from 'react'

export default function CmeCertificateIcon({ size = 18, style }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M6.85557 8.32937C6.85557 8.69381 7.13979 8.99666 7.49919 8.99666H12.2891C12.6485 8.99666 12.9327 8.69381 12.9327 8.32937C12.9327 7.96493 12.6485 7.66208 12.2891 7.66208H7.49919C7.13979 7.66208 6.85557 7.96493 6.85557 8.32937Z"
        fill="#262626"
        stroke="#262626"
        strokeWidth="0.2"
      />
      <path
        d="M7.49968 10.061C7.14028 10.061 6.85605 10.3639 6.85605 10.7283C6.85605 11.0927 7.14028 11.3956 7.49968 11.3956H10.1544C10.5138 11.3956 10.798 11.0927 10.798 10.7283C10.798 10.3639 10.5138 10.061 10.1544 10.061H7.49968Z"
        fill="#262626"
        stroke="#262626"
        strokeWidth="0.2"
      />
      <path
        d="M3.7806 6.86488V16.9269C3.7806 17.5365 4.02742 18.122 4.45853 18.5328L4.45856 18.5329C4.84247 18.8984 5.34732 19.1 5.86966 19.1C5.87544 19.1 5.88086 19.0997 5.88389 19.0996L5.88436 19.0996L5.8896 19.0994H16.9131C18.0674 19.0994 18.9997 18.1208 18.9997 16.9262V15.9908V15.9907C18.9991 15.4536 18.5783 15.009 18.0535 15.009H16.696V11.5692C16.696 11.2047 16.4118 10.9019 16.0523 10.9019C15.6929 10.9019 15.4087 11.2047 15.4087 11.5692V15.009H7.60201C7.07659 15.009 6.65577 15.4536 6.65577 15.9915V16.8578C6.65208 17.4219 6.25339 17.7158 5.85495 17.7326C5.65472 17.7411 5.45856 17.679 5.31346 17.5486C5.16993 17.4197 5.06846 17.2168 5.06846 16.9269V6.19759C5.06846 6.06669 5.07227 5.92728 5.0762 5.78386C5.0825 5.55365 5.08909 5.31312 5.08062 5.08088C5.06858 4.75077 5.02644 4.41785 4.90732 4.11165H10.9544C11.3138 4.11165 11.598 3.8088 11.598 3.44436C11.598 3.07992 11.3138 2.77708 10.9544 2.77708H2.98725C2.97612 2.77708 2.96682 2.77871 2.96013 2.78031C2.9601 2.78031 2.96008 2.78032 2.96006 2.78032C1.81838 2.79246 0.9 3.76305 0.9 4.94704V5.88306C0.9 6.42021 1.32013 6.86488 1.84503 6.86488H3.7806ZM2.97275 2.78023L2.97229 2.78023L2.97275 2.78023ZM2.18725 5.53031V4.94704C2.18725 4.48418 2.54662 4.11417 2.98604 4.11417C3.41972 4.11417 3.78 4.48387 3.78 4.94704V5.53031H2.18725ZM17.7119 16.9269C17.7119 17.3933 17.3497 17.7654 16.9131 17.7654H7.7805C7.94979 17.3271 7.94694 16.8526 7.94428 16.4092C7.94415 16.3883 7.94403 16.3675 7.94392 16.3468H16.0523C16.0621 16.3468 16.0703 16.3455 16.0763 16.3442H17.7119V16.9269Z"
        fill="#262626"
        stroke="#262626"
        strokeWidth="0.2"
      />
      <path
        d="M17.6737 7.29787C18.5362 6.65788 19.0999 5.60874 19.0999 4.42515C19.0999 2.48489 17.5864 0.9 15.717 0.9C13.8475 0.9 12.3341 2.48553 12.3341 4.42515L12.3341 4.4252C12.3347 5.60877 12.899 6.65852 13.762 7.29853V9.52884C13.762 9.72829 13.848 9.91704 13.997 10.0442L13.9972 10.0444C14.1128 10.1427 14.2577 10.1961 14.4056 10.1961C14.4496 10.1961 14.492 10.1916 14.5351 10.1827L14.5359 10.1826L15.7182 9.92987L16.8999 10.1826L16.9001 10.1826C17.0884 10.2225 17.2884 10.172 17.4386 10.0444L17.4387 10.0442C17.5878 9.917 17.6737 9.7276 17.6737 9.52884V7.29787ZM16.3853 7.87677V8.70914L15.8479 8.59399L15.8475 8.59392C15.762 8.57593 15.6737 8.57593 15.5883 8.59392L15.5879 8.59399L15.0492 8.70929V7.87736C15.2649 7.92358 15.4877 7.94967 15.717 7.94967C15.9469 7.94967 16.1697 7.92311 16.3853 7.87677ZM15.7176 2.23457C16.8688 2.23457 17.8126 3.21316 17.8126 4.42515C17.8126 5.63714 16.8688 6.61573 15.7176 6.61573C14.5663 6.61573 13.6225 5.63714 13.6225 4.42515C13.6225 3.21316 14.5663 2.23457 15.7176 2.23457Z"
        fill="#262626"
        stroke="#262626"
        strokeWidth="0.2"
      />
    </svg>
  )
}
