import { takeLatest, all } from 'redux-saga/effects'

import { makePOSTHeadersFromParams } from 'mednet-cns/src/api/v1'
import { makeURL } from 'mednet-util/src/router'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
  requestAction,
} from '../cns-util/reducer'

export const REGISTER = 'registration/REGISTER'
export const RESET_REGISTRATION_STATE = 'registration/RESET_REGISTRATION_STATE'
export const VALIDATE_UNIQUE_REGISTRATION_EMAIL =
  'registration/VALIDATE_UNIQUE_REGISTRATION_EMAIL'

export function register(params, callback) {
  return {
    type: REGISTER,
    params,
    callback,
  }
}

export function resetRegistrationState() {
  return {
    type: RESET_REGISTRATION_STATE,
  }
}

export function validateUniqueRegistrationEmail(email, callback) {
  return {
    type: VALIDATE_UNIQUE_REGISTRATION_EMAIL,
    requestId: email,
    email,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    REGISTER,
    makeURL('user/registration/registerJSON', { XDEBUG_SESSION_START: 1 }),
    (action) => makePOSTHeadersFromParams(action.params)
  )

  yield makeFetchEffect(
    takeLatest,
    VALIDATE_UNIQUE_REGISTRATION_EMAIL,
    (action) =>
      makeURL(`user/registration/validateUniqueEmailJSON/email/${action.email}`)
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  response: {},
  validatedEmails: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(REGISTER): {
      return receiveReducer(state, action, () => ({
        response: action.response,
      }))
    }

    case requestAction(REGISTER): {
      return {
        ...state,
        requestParams: action.params,
      }
    }

    case RESET_REGISTRATION_STATE: {
      return initialState
    }

    case receiveAction(VALIDATE_UNIQUE_REGISTRATION_EMAIL): {
      return receiveReducer(state, action, () => ({
        validatedEmails: {
          ...state.validatedEmails,
          [action.email]: !action.response.exists,
        },
      }))
    }
    default:
      return state
  }
}
