import React from 'react'
import ReactPlayer from 'react-player/vimeo'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import 'core-js/features/url-search-params'
import moment from 'moment'

import {
  getMixpanelReferrerProperties,
  mixpanel,
} from 'mednet-util/src/tracking'
import { mednetAPI } from 'mednet-cns/src/api/v1'
import { makeAssetURL, makeURL } from 'mednet-util/src/router'
import { BaseQuestionLink } from 'pharmacy/src/navigation/questionLink'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Page } from 'pharmacy/src/display/page'
import { QuestionActions } from 'pharmacy/src/question/questionActions'
import { QuestionInformation } from 'pharmacy/src/question/questionInformation'
import { QuestionAnswers } from 'pharmacy/src/question/questionAnswers'
import { RelatedQuestions } from 'pharmacy/src/question/relatedQuestions'
import { CitedPapers } from 'pharmacy/src/display/citedPapers'
import { WriteAnswer } from 'pharmacy/src/answer/writeAnswer'
import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'
import { ShareModal } from 'pharmacy/src/display/shareModal'
import { QuestionRequestModal } from 'pharmacy/src/question/questionRequestModal'
import { QuestionPoll } from 'pharmacy/src/question/questionPoll'
import { QuestionDisclaimer } from 'pharmacy/src/question/questionDisclaimer'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import {
  fetchQuestionMetadata,
  fetchQuestionAnswers,
  fetchRelatedQuestions,
  fetchQuestionCitedPapers,
  fetchQuestionUpdates,
  fetchCampaignQuestionMetadata,
  fetchCampaignQuestionAnswers,
  fetchCampaignRelatedQuestions,
  fetchCampaignQuestionCitedPapers,
  fetchCampaignQuestionUpdates,
  FETCH_QUESTION_METADATA,
  FETCH_QUESTION_UPDATES,
  FETCH_QUESTION_CITED_PAPERS,
  FETCH_CAMPAIGN_QUESTION_METADATA,
  FETCH_CAMPAIGN_QUESTION_UPDATES,
  FETCH_CAMPAIGN_QUESTION_CITED_PAPERS,
} from 'mednet-cns/src/reducers/question'
import {
  cclickPlace,
  downloadableStatuses,
} from 'mednet-util/src/constants/publication'

import RegistrationModal from 'pharmacy/src/question/registerModal/registerModal'
import { QuestionViewCme } from 'pharmacy/src/question/questionViewCme'
import QuestionAuthor from 'pharmacy/src/question/questionAuthor/questionAuthor'
import { Subtitle3 } from 'pharmacy/src/typography'
import QuestionSponsorship from 'pharmacy/src/question/questionSponsorship/questionSponsorship'
import { QuestionImages } from 'pharmacy/src/question/questionImages'
import QuestionProgramDialog from 'pharmacy/src/program/questionProgramDialog'

import { openModal } from 'mednet-cns/src/reducers/modal'

import { CAMPAIGN_REGISTER_MODAL } from 'mednet-util/src/constants/modal'
import {
  getLatestPublishedNotCompletedCmeSponsorship,
  getLatestPublishedSponsorship,
} from 'mednet-util/src/sponsorship'

import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { QuestionFooter } from 'components/questionFooter'
import { ErrorPage } from 'pages/error'

import css from './question.scss'
import AddToCurriculumButton from './addToCurriculumButton'
import CmeHighlightFooter from './cmeHighlightFooter'

class QuestionPage extends React.Component {
  constructor(props) {
    super(props)

    if (!props.metadataIsLoaded) {
      this.fetchInitialData(this.trackView)
    }

    this.transformCitationLinks = this.transformCitationLinks.bind(this)
  }

  componentDidMount() {
    this.fetchRestData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.questionId !== this.props.questionId) {
      this.fetchInitialData(this.trackView)
      this.fetchRestData()
    }
  }

  fetchInitialData = (callback) => {
    this.props.fetchQuestionMetadata(callback)
  }

  fetchRestData = () => {
    this.props.fetchQuestionAnswers()
    this.props.fetchRelatedQuestions()
    this.props.fetchQuestionCitedPapers()
    this.props.fetchQuestionUpdates()
  }

  trackView = () => {
    const {
      history,
      questionId,
      specialtyId,
      topics,
      isFollowed,
      isAnonymous,
      pollId,
    } = this.props

    const params = new URLSearchParams(history.location.search)
    const src = params.get('src')
    const pos = params.get('pos')
    const { direct_referrer_label } = getMixpanelReferrerProperties()

    const { referrer_source, item_referrer_position } = !src
      ? {
          referrer_source: direct_referrer_label,
          item_referrer_position: undefined,
        }
      : {
          referrer_source: src,
          item_referrer_position: pos,
        }

    mixpanel.track('viewed_question_page', {
      question_id: questionId,
      question_specialty: specialtyId,
      question_topics: topics.map((topic) => topic.topicId),
      is_anonymous: isAnonymous ? true : false,
      is_starred: isFollowed ? true : false,
      referrer_source,
      item_referrer_position,
      has_poll: typeof pollId === 'undefined' ? false : true,
      poll_id: pollId,
    })
  }

  transformCitationLinks = (place, stubbedAction) => (link) => {
    if (stubbedAction) {
      link.onmousedown = stubbedAction
      link.removeAttribute('href')
      link.setAttribute('role', 'button')
      if (
        link.dataset.citation === undefined &&
        link.className &&
        link.className.indexOf('mention') !== -1
      ) {
        link.textContent = '@theMednet Member'
      }
      return
    }

    if (link.dataset.citation !== undefined) {
      const citeId = Number(link.dataset.citation.replace('cite_', ''))
      const citation = this.props.citations.find(
        (citation) => citation.citeId === citeId
      )

      // This is for clicks from a question detail or an answer, not from the cited papers list
      if (
        citation &&
        downloadableStatuses.includes(citation.publication.access)
      ) {
        const icon = document.createElement('i')
        icon.className = 'fas fa-file-download'
        if (place === cclickPlace.question) {
          link.setAttribute('href', `/questionCitedPaper/download/${citeId}`)
        } else {
          link.setAttribute('href', `/answerCitedpaper/download/${citeId}`)
        }
        link.appendChild(document.createTextNode(' '))
        link.appendChild(icon)
      } else if (citation && !_.isEmpty(citation.publication.publicURL)) {
        link.setAttribute('href', citation.publication.publicURL)
      }

      // This is for clicks from a question detail or an answer, not from the cited papers list
      if (place === cclickPlace.question) {
        link.onmousedown = () => {
          mednetAPI(`questionCitedPaper/clickTrackJSON/${citeId}`)
          mixpanel.track('Clicked Question Details Citation', { id: citeId })
        }
      } else {
        link.onmousedown = () => {
          mednetAPI(`answerCitedpaper/clickTrackJSON/${citeId}`)
          mixpanel.track('Clicked Citation', { id: citeId })
        }
      }
    }
  }

  render() {
    const {
      metadataIsLoaded,
      updatesAreLoaded,
      citationsAreLoaded,
      question,
      slug,
      numAnswers,
      isError,
      errorStatus,
      questionId,
      questionUpdateId,
      updates,
      stubbed,
      campaignId,
      hash,
      openRegistrationModal,
      scroll,
      answerId,
      sponsorships,
      numViews,
      isExpertView,
      images,
      history,
    } = this.props

    if (!metadataIsLoaded || !updatesAreLoaded || !citationsAreLoaded) {
      return <StarLoader />
    }

    if (isError) {
      return <ErrorPage errorStatus={errorStatus} />
    }

    // https://themednet.atlassian.net/browse/ENG-414
    // priority to scroll to answerId if exists
    const update =
      answerId || !questionUpdateId || !scroll
        ? undefined
        : _.find(updates, { questionUpdateId })

    const answerIdHodler = !(update && (update.answer || update.comment))
      ? undefined
      : update.answer || update.comment

    const scrollToAnswerId = answerIdHodler
      ? answerIdHodler.answerId
      : scroll && answerId && parseInt(answerId)

    const isPartOfActivePaidSponsorship =
      (sponsorships?.filter(
        (sponsorship) =>
          Number(sponsorship.isPaid) === 1 &&
          moment(new Date()).isBetween(
            sponsorship.startDate,
            sponsorship.endDate
          )
      )?.length ?? 0) > 0

    const latestPublishedSponsorship =
      getLatestPublishedSponsorship(sponsorships)

    const latestPublishedCmeSponsorship =
      getLatestPublishedNotCompletedCmeSponsorship(sponsorships)

    const hideProgramDialog =
      this.props.flags?.enableCmeCollection &&
      Boolean(latestPublishedCmeSponsorship)

    return (
      <Page>
        <Helmet>
          <title>{`${question}`}</title>
        </Helmet>
        <ErrorBoundary>
          <ShareModal />
          {!stubbed ? null : (
            <RegistrationModal campaignId={campaignId} history={history} />
          )}
        </ErrorBoundary>
        <ErrorBoundary>
          <QuestionRequestModal />
        </ErrorBoundary>

        <div className={css.content}>
          <div className={css.leftColumn}>
            <ErrorBoundary>
              <QuestionAuthor questionId={questionId} stubbed={stubbed} />
            </ErrorBoundary>
            <div className={css.viewsContainer}>
              <Subtitle3>This question has been viewed</Subtitle3>
              <Subtitle3 className={css.numViews}>{numViews} times</Subtitle3>
            </div>
            {!stubbed && !isPartOfActivePaidSponsorship && (
              <ErrorBoundary>
                <div className={css.questionViewCmeLeftContainer}>
                  <QuestionViewCme questionId={questionId} vertical />
                </div>
              </ErrorBoundary>
            )}
            <ErrorBoundary>
              <QuestionSponsorship questionId={questionId} stubbed={stubbed} />
            </ErrorBoundary>
          </div>

          <div className={css.rightContent}>
            <div style={{ display: 'flex', flexWrap: 'wrap-reverse' }}>
              <div className={css.middleColumn}>
                <div>
                  <QuestionInformation
                    questionId={questionId}
                    detailProps={{
                      transformLink: this.transformCitationLinks(
                        'Q',
                        stubbed && openRegistrationModal
                      ),
                    }}
                    stubbed={stubbed}
                    enableProgramHighlight={process.env.PROGRAMS_PAGES_RELEASED}
                  />
                  <div className={css.displayForSmallQuestionScreen}>
                    <ErrorBoundary>
                      <QuestionAuthor
                        questionId={questionId}
                        stubbed={stubbed}
                      />
                    </ErrorBoundary>
                  </div>
                </div>
                <div className={css.actionsContainer}>
                  <ErrorBoundary>
                    <hr className={css.divider} />
                    <QuestionActions
                      questionId={questionId}
                      stubbed={stubbed}
                    />
                  </ErrorBoundary>
                </div>
                <div className={css.displayForSmallQuestionScreen}>
                  <ErrorBoundary>
                    <QuestionSponsorship questionId={questionId} />
                  </ErrorBoundary>
                </div>
                {process.env.PROGRAMS_PAGES_RELEASED &&
                  latestPublishedSponsorship &&
                  !hideProgramDialog && (
                    <ErrorBoundary>
                      <QuestionProgramDialog
                        sponsorship={latestPublishedSponsorship}
                        questionId={questionId}
                        stubbed={stubbed}
                      />
                    </ErrorBoundary>
                  )}
                <ErrorBoundary>
                  <QuestionPoll
                    className={css.pollContainer}
                    questionId={questionId}
                    campaignHash={hash}
                  />
                </ErrorBoundary>

                {isExpertView && (
                  <ErrorBoundary>
                    <hr className={css.divider} />
                    <div className={css.writeAnswerContainer}>
                      <WriteAnswer questionId={questionId} />
                    </div>
                  </ErrorBoundary>
                )}
                <hr className={css.divider} />
              </div>

              <div className={css.rightActions}>
                <AddToCurriculumButton questionId={questionId} />
              </div>
            </div>

            <div className={css.bottomContent}>
              <div className={css.middleColumn}>
                {numAnswers > 0 && (
                  <QuestionAnswers
                    questionId={questionId}
                    scrollToAnswerId={scrollToAnswerId}
                    stubbed={stubbed}
                    hash={hash}
                    answerProps={{
                      bodyProps: {
                        transformLink: this.transformCitationLinks(
                          'A',
                          stubbed && openRegistrationModal
                        ),
                      },
                    }}
                    commentProps={{
                      bodyProps: {
                        transformLink: this.transformCitationLinks(
                          'A',
                          stubbed && openRegistrationModal
                        ),
                      },
                    }}
                  />
                )}
                <ErrorBoundary>
                  {!stubbed && !isPartOfActivePaidSponsorship && (
                    <div className={css.questionViewCmeMiddleContainer}>
                      <QuestionViewCme
                        questionId={questionId}
                        scrollToCenterWhenRedeemded
                        largeTitle
                      />
                    </div>
                  )}
                </ErrorBoundary>
                {!isExpertView && (
                  <ErrorBoundary>
                    <div className={css.writeAnswerContainer}>
                      <WriteAnswer questionId={questionId} />
                    </div>
                  </ErrorBoundary>
                )}
              </div>
              <div className={css.rightColumn}>
                <ErrorBoundary>
                  <QuestionImages
                    images={images}
                    onClick={(imagePath) =>
                      window?.open(makeAssetURL(imagePath), '_blank')
                    }
                  />
                </ErrorBoundary>

                <CitedPapers
                  questionId={questionId}
                  className={css.citedPapersContainer}
                  VideoComponent={ReactPlayer}
                  stubbed={stubbed}
                />
                {numAnswers > 0 && (
                  <div className={css.relatedQuestionsContainer}>
                    <ErrorBoundary>
                      <RelatedQuestions
                        type="text"
                        questionId={questionId}
                        stubbed={stubbed}
                        hash={hash}
                        enableProgramHighlight={
                          process.env.PROGRAMS_PAGES_RELEASED
                        }
                      />
                    </ErrorBoundary>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {stubbed ? null : (
          <div className={css.relatedQuestionsFeedContainer}>
            <div className={css.leftColumnSpacer} />
            <div className={css.rightFeedContent}>
              <ErrorBoundary>
                <RelatedQuestions
                  type="feed"
                  questionId={questionId}
                  stubbed={stubbed}
                  hash={hash}
                  enableProgramHighlight={process.env.PROGRAMS_PAGES_RELEASED}
                />
              </ErrorBoundary>
              <div className={css.rightColumnSpacer} />
            </div>
          </div>
        )}

        <div className={css.footerContent}>
          <div className={css.leftColumnSpacer} />
          <div className={css.rightFeedContent}>
            <div className={css.disclaimerContainer}>
              <QuestionFooter />
            </div>
            <div
              className={[css.printSource, css.disclaimerContainer].join(' ')}
            >
              <BaseQuestionLink slug={slug} questionId={questionId}>
                Source:{' '}
                {makeURL(slug ? slug : `question/${questionId}`, {}, true)}
              </BaseQuestionLink>
            </div>
            <div className={css.disclaimerContainer}>
              <QuestionDisclaimer />
            </div>
          </div>
        </div>

        {this.props.flags?.enableCmeCollection &&
          latestPublishedCmeSponsorship && (
            <ErrorBoundary>
              <CmeHighlightFooter
                sponsorship={latestPublishedCmeSponsorship}
                questionId={questionId}
                stubbed={stubbed}
              />
            </ErrorBoundary>
          )}
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId, hash, campaignId } = ownProps.match.params
  const stubbed = Boolean(hash)

  const {
    question,
    numAnswers,
    citedPapers = [],
    citations = [],
    slug,
    updates,
    sponsorships,
    userId,
    topics,
    isFollowed,
    isAnonymous,
    pollId,
    numViews,
    images,
  } = state.question.questions[questionId] || {}

  const { specialtyId } = state.user.users[userId] || {}

  const isExpertView = state.user.data?.isExpert

  let metadataRequest = {}
  let updatesRequest = {}
  let citationsRequest = {}

  if (stubbed) {
    metadataRequest =
      _.get(
        state.request.requests,
        makeRequestName(FETCH_CAMPAIGN_QUESTION_METADATA, hash)
      ) || {}
    updatesRequest =
      _.get(
        state.request.requests,
        makeRequestName(FETCH_CAMPAIGN_QUESTION_UPDATES, hash)
      ) || {}
    citationsRequest =
      _.get(
        state.request.requests,
        makeRequestName(FETCH_CAMPAIGN_QUESTION_CITED_PAPERS, hash)
      ) || {}
  } else {
    metadataRequest =
      _.get(
        state.request.requests,
        makeRequestName(FETCH_QUESTION_METADATA, questionId)
      ) || {}
    updatesRequest =
      _.get(
        state.request.requests,
        makeRequestName(FETCH_QUESTION_UPDATES, questionId)
      ) || {}
    citationsRequest =
      _.get(
        state.request.requests,
        makeRequestName(FETCH_QUESTION_CITED_PAPERS, questionId)
      ) || {}
  }

  const params = new URLSearchParams(ownProps.location.search)
  const poll = params.get('poll')
  const questionUpdateId = poll
    ? undefined
    : _.toNumber(params.get('update_id'))

  const answerId = params.get('answer_id')
  const scroll = params.get('scroll')

  return {
    citedPapers,
    citations,
    questionId,
    questionUpdateId: questionUpdateId ? questionUpdateId : undefined,
    updates,
    question,
    slug,
    numAnswers,
    hash,
    stubbed,
    campaignId,
    isError: metadataRequest.isError,
    errorStatus: metadataRequest.errorStatus,
    metadataIsLoaded: metadataRequest.isLoaded,
    updatesAreLoaded: updatesRequest.isLoaded || !questionUpdateId,
    citationsAreLoaded: citationsRequest.isLoaded,
    scroll,
    answerId,
    sponsorships,
    specialtyId,
    topics: topics || [],
    isFollowed,
    isAnonymous,
    pollId,
    isExpertView,
    numViews,
    images,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { questionId, hash } = ownProps.match.params
  const stubbed = Boolean(hash)

  const params = new URLSearchParams(ownProps.location.search)
  const poll = params.get('poll')
  const invitationId = params.get('invitation_id')
  const questionUpdateId = poll
    ? undefined
    : _.toNumber(params.get('update_id'))

  const fetchUpdates = !questionUpdateId
    ? _.noop
    : () => dispatch(fetchQuestionUpdates(questionId))

  const fetchCampaignUpdates = !questionUpdateId
    ? _.noop
    : () => dispatch(fetchCampaignQuestionUpdates(hash, questionId))

  if (stubbed) {
    return {
      fetchQuestionMetadata: (callback) => {
        dispatch(
          fetchCampaignQuestionMetadata(
            hash,
            questionId,
            window && window.localStorage
              ? window.localStorage.getItem('guestUUID')
              : null,
            invitationId,
            callback
          )
        )
      },
      fetchQuestionAnswers: () =>
        dispatch(fetchCampaignQuestionAnswers(hash, questionId)),
      fetchQuestionCitedPapers: () =>
        dispatch(fetchCampaignQuestionCitedPapers(hash, questionId)),
      fetchRelatedQuestions: () =>
        dispatch(fetchCampaignRelatedQuestions(hash, questionId)),
      fetchQuestionUpdates: fetchCampaignUpdates,
      openRegistrationModal: () =>
        dispatch(openModal(CAMPAIGN_REGISTER_MODAL.modalId)),
    }
  }
  return {
    fetchQuestionMetadata: (callback) =>
      dispatch(fetchQuestionMetadata(questionId, callback)),
    fetchQuestionAnswers: () => dispatch(fetchQuestionAnswers(questionId)),
    fetchQuestionCitedPapers: () =>
      dispatch(fetchQuestionCitedPapers(questionId)),
    fetchRelatedQuestions: () => dispatch(fetchRelatedQuestions(questionId)),
    fetchQuestionUpdates: fetchUpdates,
  }
}

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(QuestionPage)
)
