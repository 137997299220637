import React, { useEffect, useState } from 'react'

import _ from 'lodash'

import { Icon } from 'pharmacy/src/display/icon'

import classNames from 'classnames'

import css from './headerLlmSearch.scss'

const INPUT_PLACEHOLDER_VALUES = [
  'What is the best strategy for treating resistant hypertension in CKD?',
  'How do you manage post-COVID pulmonary complications?',
  'What is optimal adjuvant therapy for resected high-risk melanoma?',
  'What is the best approach for managing post-stroke spasticity?',
  'What is the best treatment for hypercalcemia of malignancy?',
]

const FADING_ANIMATION_STATE = {
  IN: 'in',
  OUT: 'out',
  NONE: 'none',
}

const openSearchPage = (value) => {
  if (!value?.trim() || typeof window === 'undefined') return

  const params = `query=${encodeURIComponent(value)}&src=popover-search`
  window.location.assign(`/v2/search/questions?${params}`)
}

export const HeaderLlmSearch = () => {
  const [searchQuery, setSearchQuery] = useState()
  const [placeholderIndex, setAnimatedPlaceholderIndex] = useState(0)
  const [placeholderFadingAnimation, setPlaceholderFadingAnimation] = useState(
    FADING_ANIMATION_STATE.IN
  )

  useEffect(() => {
    let intervalId
    if (_.isEmpty(searchQuery)) {
      intervalId = setInterval(() => {
        setPlaceholderFadingAnimation(FADING_ANIMATION_STATE.OUT)

        setTimeout(() => {
          setAnimatedPlaceholderIndex(
            (prevIndex) => (prevIndex + 1) % INPUT_PLACEHOLDER_VALUES.length
          )
          setPlaceholderFadingAnimation(FADING_ANIMATION_STATE.IN)
        }, 1000)
      }, 4000)
    }

    return () => intervalId && clearInterval(intervalId)
  }, [searchQuery])

  const submitSearch = () => {
    openSearchPage(searchQuery)
  }

  return (
    <div className={css.searchInputContainer}>
      <Icon
        className={css.searchIcon}
        icon={['far', 'search']}
        onClick={submitSearch}
      />
      <input
        className={classNames(css.searchInput, {
          [css.fadedOutPlaceholder]:
            placeholderFadingAnimation === FADING_ANIMATION_STATE.OUT,
          [css.searchInputWithValue]: !_.isEmpty(searchQuery),
        })}
        onChange={(e) => setSearchQuery(e.target.value)}
        value={searchQuery}
        type="text"
        placeholder={INPUT_PLACEHOLDER_VALUES[placeholderIndex]}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submitSearch()
          }
        }}
      />
      {!_.isEmpty(searchQuery) && (
        <Icon
          className={css.clearIcon}
          icon={['far', 'times']}
          onClick={() => setSearchQuery('')}
        />
      )}
    </div>
  )
}
