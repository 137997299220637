import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'

import { Modal } from 'pharmacy/src/display/modal'
import { Header2, Subtitle1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import { REGISTRATION_SPECIALTY_MODAL } from 'mednet-util/src/constants/modal'

import css from './specialtyModal.scss'

// This is just needed for the registration flow not all over the website, so hardcoding it here
const GENERAL_SPECIALTIES_MAP = {
  'General Internal Medicine': ['Hospital Medicine', 'Primary Care'],
}

const SUPER_SPECIALTY_GROUPS = {
  'Internal Medicine': {
    Attending: [
      'General Internal Medicine',
      'Hospital Medicine',
      'Primary Care',
    ],
    Resident: ['General Internal Medicine'],
  },
}

const areOptionsMultiSelect = (options) => {
  const generalSpecialties = options.filter((s) =>
    Boolean(GENERAL_SPECIALTIES_MAP[s.specialty])
  )

  // Don't allow merging into general specialties unless the user can select either one or all options
  // because we need to map to a single specialty in the end
  // (That means merging is allowed when we have 3 options and selecting 2 of them will map to the 3rd
  // while selecting one will just work as normal)
  if (
    options.length === 3 &&
    generalSpecialties.length === 1 &&
    GENERAL_SPECIALTIES_MAP[generalSpecialties[0].specialty]?.length === 2
  ) {
    return true
  }

  return false
}

const SpecialtySubForm = ({
  groupName,
  options,
  setSelectedSpecialtyIds,
  selectedSpecialtyIds,
  setErrorMessage,
}) => {
  const isMultiSelect = useMemo(() => {
    return areOptionsMultiSelect(options)
  }, [options])

  const visibleOptions = useMemo(() => {
    if (isMultiSelect) {
      return options.filter((s) => !GENERAL_SPECIALTIES_MAP[s.specialty])
    }

    return options
  }, [])

  const onOptionChange = useCallback(
    (event, specialty) => {
      setErrorMessage(null)
      if (event.target.checked) {
        if (isMultiSelect) {
          setSelectedSpecialtyIds((prev) =>
            _.uniq([...prev, specialty.specialtyId])
          )
        } else {
          setSelectedSpecialtyIds([specialty.specialtyId])
        }
      } else {
        setSelectedSpecialtyIds((prev) =>
          _.without(prev, specialty.specialtyId)
        )
      }
    },
    [isMultiSelect, setSelectedSpecialtyIds]
  )

  if (options.length === 1) {
    return null
  }

  return (
    <div>
      <Subtitle1 className={css.question}>
        Please specify your area{isMultiSelect ? '(s)' : ''} of practice{' '}
        {isMultiSelect ? '(select all that apply)' : ''}
      </Subtitle1>
      <div className={css.optionsContainer}>
        {visibleOptions.map((specialty) => (
          <div key={specialty.specialtyId}>
            <Subtitle1 className={css.option}>
              <input
                type={isMultiSelect ? 'checkbox' : 'radio'}
                id={`specialtyModal${groupName ?? ''}_${specialty.specialtyId}`}
                name={
                  isMultiSelect
                    ? `specialtyModal${groupName ?? ''}_${
                        specialty.specialtyId
                      }`
                    : `specialtyModal${groupName ?? ''}_option`
                }
                onChange={(e) => onOptionChange(e, specialty)}
                checked={selectedSpecialtyIds.includes(specialty.specialtyId)}
              />
              <label
                htmlFor={`specialtyModal${groupName ?? ''}_${
                  specialty.specialtyId
                }`}
              >
                {specialty.specialty}
              </label>
            </Subtitle1>
          </div>
        ))}
      </div>
    </div>
  )
}

const SpecialtyForm = ({
  superSpecialty,
  specialties,
  onSubmit,
  defaultSpecialtyId,
  closeModal,
  onCancel,
}) => {
  const nameToSpecialty = useMemo(
    () => _.keyBy(specialties, 'specialty'),
    [specialties]
  )

  const idToSpecialty = useMemo(
    () => _.keyBy(specialties, 'specialtyId'),
    [specialties]
  )

  const [selectedGroup, setSelectedGroup] = useState(null)
  const [selectedSpecialtyIds, setSelectedSpecialtyIds] = useState([])
  const [finalSelectedSpecialtyId, setFinalSelectedSpecialtyId] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const areOptionsGrouped = Boolean(
    SUPER_SPECIALTY_GROUPS[superSpecialty.superSpecialty]
  )

  const options = useMemo(() => {
    return specialties.filter(
      (s) =>
        s.superSpecialty?.superSpecialtyId === superSpecialty.superSpecialtyId
    )
  }, [specialties, superSpecialty])

  const optionGroups = useMemo(
    () =>
      _.mapValues(
        SUPER_SPECIALTY_GROUPS[superSpecialty.superSpecialty] ?? {},
        (specialtyNames) =>
          specialtyNames.map((specialtyName) => nameToSpecialty[specialtyName])
      ),
    [superSpecialty, nameToSpecialty]
  )

  useEffect(() => {
    const defaultSpecialty = idToSpecialty[defaultSpecialtyId ?? 0]
    const validDefaultSpecialty =
      defaultSpecialty &&
      options
        .map((specialty) => specialty.specialtyId)
        .includes(defaultSpecialty.specialtyId)

    if (validDefaultSpecialty) {
      setSelectedSpecialtyIds(
        GENERAL_SPECIALTIES_MAP[defaultSpecialty.specialty]
          ? GENERAL_SPECIALTIES_MAP[defaultSpecialty.specialty].map(
              (specialtyName) => nameToSpecialty[specialtyName].specialtyId
            )
          : [defaultSpecialtyId]
      )
    } else {
      setSelectedSpecialtyIds([])
    }
  }, [defaultSpecialtyId, idToSpecialty, nameToSpecialty, options])

  const onGroupChange = useCallback(
    (event, groupName) => {
      if (event.target.checked) {
        setSelectedGroup(groupName)
        const selectedGroupOptions = optionGroups[groupName]
        const selectedGroupOptionIds = selectedGroupOptions?.map(
          (specialty) => specialty.specialtyId
        )

        if (!selectedGroupOptions?.length) {
          setSelectedSpecialtyIds([])
        } else if (selectedGroupOptions.length === 1) {
          setSelectedSpecialtyIds([selectedGroupOptionIds[0]])
        } else {
          const isGroupMultiSelect = areOptionsMultiSelect(selectedGroupOptions)

          setSelectedSpecialtyIds((prev) => {
            return prev
              .flatMap((specialtyId) => [
                ...(isGroupMultiSelect &&
                GENERAL_SPECIALTIES_MAP[idToSpecialty[specialtyId].specialty]
                  ? [
                      GENERAL_SPECIALTIES_MAP[
                        idToSpecialty[specialtyId].specialty
                      ].map(
                        (specialtyName) =>
                          nameToSpecialty[specialtyName]?.specialtyId
                      ),
                    ]
                  : [specialtyId]),
              ])
              .filter(
                (specialtyId) =>
                  Boolean(specialtyId) &&
                  selectedGroupOptionIds.includes(specialtyId)
              )
          })
        }
      }
    },
    [idToSpecialty, nameToSpecialty, optionGroups]
  )

  useEffect(() => {
    if (selectedSpecialtyIds.length === 1) {
      setFinalSelectedSpecialtyId(selectedSpecialtyIds[0])
      return
    }

    if (selectedSpecialtyIds.length > 1) {
      const generalSpecialtyName = _.entries(GENERAL_SPECIALTIES_MAP).find(
        ([, specificSpecialtiesName]) => {
          const specificSpecialties = specificSpecialtiesName.map(
            (specificSpecialtyName) => nameToSpecialty[specificSpecialtyName]
          )
          return _.isEqual(
            _.sortBy(selectedSpecialtyIds),
            _.sortBy([
              ...specificSpecialties.map(
                (specificSpecialty) => specificSpecialty.specialtyId
              ),
            ])
          )
        }
      )?.[0]

      if (
        generalSpecialtyName &&
        nameToSpecialty[generalSpecialtyName]?.specialtyId
      ) {
        setFinalSelectedSpecialtyId(
          nameToSpecialty[generalSpecialtyName]?.specialtyId
        )
        return
      }
    }

    setFinalSelectedSpecialtyId(null)
  }, [selectedSpecialtyIds, nameToSpecialty])

  const handleSubmit = useCallback(() => {
    if (areOptionsGrouped && !selectedGroup) {
      setErrorMessage('Please select your current role')
      return
    }

    if (finalSelectedSpecialtyId) {
      setErrorMessage(null)
      onSubmit(finalSelectedSpecialtyId)
    } else {
      if (!selectedSpecialtyIds.length) {
        setErrorMessage('Please select at least one option')
      }
      // This code should never be reached
      else {
        Sentry.captureException(
          new Error(
            `Multiple specialties were selected but could not me mapped to a single general specialty (${selectedSpecialtyIds.join(
              ', '
            )})`
          )
        )

        setErrorMessage(
          'Something went wrong. Please contact support contact@themednet.org.'
        )
      }
    }
  }, [finalSelectedSpecialtyId, onSubmit, selectedGroup, areOptionsGrouped])

  return (
    <div>
      <Header2>Practice Area Details</Header2>
      <hr />

      {areOptionsGrouped && (
        <div>
          <Subtitle1 className={css.question}>
            Please specify your current role:
          </Subtitle1>
          <div className={css.optionsContainer}>
            {_.keys(optionGroups).map((groupName) => (
              <div key={groupName}>
                <Subtitle1 className={css.option}>
                  <input
                    type="radio"
                    id={`specialtyModalGroup_${groupName}`}
                    name="specialtyModalGroup"
                    onChange={(e) => onGroupChange(e, groupName)}
                    checked={selectedGroup === groupName}
                  />
                  <label htmlFor={`specialtyModalGroup_${groupName}`}>
                    {groupName}
                  </label>
                </Subtitle1>
                {optionGroups[groupName].length > 1 &&
                  selectedGroup === groupName && (
                    <div className={css.groupSubFormContainer}>
                      <SpecialtySubForm
                        groupName={groupName}
                        options={optionGroups[groupName]}
                        setSelectedSpecialtyIds={setSelectedSpecialtyIds}
                        selectedSpecialtyIds={selectedSpecialtyIds}
                        setErrorMessage={setErrorMessage}
                      />
                    </div>
                  )}
              </div>
            ))}
          </div>
        </div>
      )}

      {!areOptionsGrouped && (
        <SpecialtySubForm
          options={options}
          setSelectedSpecialtyIds={setSelectedSpecialtyIds}
          selectedSpecialtyIds={selectedSpecialtyIds}
          setErrorMessage={setErrorMessage}
        />
      )}

      {errorMessage && <div className={css.errorMessage}>{errorMessage}</div>}

      <div className={css.buttonGroup}>
        <Button
          type="secondary"
          className={css.button}
          onClick={() => {
            onCancel()
            closeModal()
          }}
        >
          Cancel
        </Button>
        <Button type="primary" className={css.button} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  )
}

export const SpecialtyModal = (props) => {
  return (
    <Modal
      modalId={REGISTRATION_SPECIALTY_MODAL.modalId}
      closeOnBackdrop={false}
      {...props}
    >
      <SpecialtyForm />
    </Modal>
  )
}
