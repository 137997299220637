import React from 'react'
import classNames from 'classnames'

import { Link1 } from 'pharmacy/src/typography'
import { Link } from 'pharmacy/src/navigation/link'
import { Icon } from 'pharmacy/src/display/icon'

import css from './menuChildren.scss'

export class MenuChild extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  toggleSubMenu = (isOpen) => {
    this.setState({ isOpen: !isOpen })
  }

  render() {
    const { children: content } = this.props

    if (content.type) {
      return content
    }

    const {
      label,
      header,
      children: subchildren,
      external = true,
      labelClassName,
      ...restProps
    } = content

    if (header) {
      const isOpen = this.state.isOpen

      const headerClasses = classNames(css.linkContainer, {
        [css.activeHeader]: isOpen,
      })
      const iconClasses = classNames(css.icon, {
        [css.icon_inactive]: !isOpen,
        [css.icon_active]: isOpen,
      })

      return (
        <div>
          <hr className={css.subsectionSeperator} />

          {Array.isArray(subchildren) ? (
            <Link1
              className={headerClasses}
              onClick={() => this.toggleSubMenu(isOpen)}
            >
              {header}
              <Icon className={iconClasses} icon={['fas', 'caret-down']} />
            </Link1>
          ) : (
            <Link
              className={css.link}
              pathname={content.pathname}
              external={external}
              {...restProps}
            >
              <Link1 className={css.linkContainer}>{header}</Link1>
            </Link>
          )}

          {isOpen && <hr className={css.activeHeaderSeperator} />}
          {isOpen && (
            <div className={css.subchildrenContainer}>
              {subchildren
                .filter((child) => child)
                .map((child, index) => (
                  <MenuChild key={index}>{child}</MenuChild>
                ))}
            </div>
          )}
        </div>
      )
    }

    const labelText = (
      <Link1 className={css.linkContainer}>
        <span className={labelClassName}>{label}</span>
      </Link1>
    )

    if (content.pathname) {
      return (
        <Link
          className={css.link}
          pathname={content.pathname}
          external={external}
          {...restProps}
        >
          {labelText}
        </Link>
      )
    }

    return <div {...restProps}>{labelText}</div>
  }
}

class MenuChildren extends React.Component {
  render() {
    const {
      children,
      isOpen,
      headerHeight,
      hasArrow,
      fullWidthItemsInSmallScreen,
      className,
    } = this.props

    if (!isOpen || !children) {
      return null
    }

    const childrenContainerClassNames = classNames({
      [css.children]: true,
      [css.childrenWithArrow]: hasArrow,
      [css.fullWidthItemsInSmallScreen]: fullWidthItemsInSmallScreen,
      ...(className ? { [className]: true } : {}),
    })

    return (
      <div
        className={childrenContainerClassNames}
        style={{ top: `${headerHeight}px` }}
      >
        {hasArrow && <div className={css.arrow} />}
        <div>
          {children.map((child, index) => (
            <MenuChild key={index}>{child}</MenuChild>
          ))}
        </div>
      </div>
    )
  }
}

export default MenuChildren
