import React from 'react'
import { Helmet } from 'react-helmet'

import { Header1 } from 'pharmacy/src/typography'
import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'
import { Page } from 'pharmacy/src/display/page'

import { getAppHeader } from 'mednet-util/src/header'

import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { ProfileCard } from 'components/profileCard'
import { TopicList } from 'components/topicList'
import { ScrollUpButton } from 'components/scrollUpButton'

import ProgramsList from '../../components/programsList'

import HomeFeed from './homeFeed'

import css from './home.scss'

class HomePage extends React.Component {
  constructor() {
    super()
    this.programsListRef = React.createRef()
    this.state = { topicsListTopOffset: 0 }
  }

  componentDidMount() {
    this.headerResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const programsListHeight =
          this.programsListRef.current?.getBoundingClientRect()?.height ?? 0

        this.setState({
          topicsListTopOffset: entry.contentRect.height + programsListHeight,
        })
      }
    })

    if (getAppHeader()) {
      const programsListHeight =
        this.programsListRef.current?.getBoundingClientRect()?.height ?? 0
      const headerHeight = getAppHeader().getBoundingClientRect().height ?? 0

      this.setState({
        topicsListTopOffset: headerHeight + programsListHeight,
      })

      this.headerResizeObserver.observe(getAppHeader())
    }
  }

  componentWillUnmount() {
    if (this.headerResizeObserver) {
      this.headerResizeObserver.disconnect()
    }
  }

  render() {
    const {
      questionSearchAlgorithm,
      enforceAlgoliaQuestionSearchInAutoComplete,
    } = this.props.flags ?? {}

    const enableNewSearchExperience =
      !enforceAlgoliaQuestionSearchInAutoComplete &&
      questionSearchAlgorithm === 'lux_and_llm'

    return (
      <Page>
        <Helmet>
          <title>Homepage</title>
        </Helmet>
        <div className={css.content}>
          <div className={css.profileSpacer} />
          <div className={css.profile}>
            <ErrorBoundary>
              <ProfileCard />
            </ErrorBoundary>
          </div>
          <div className={css.feed}>
            <div className={css.feedHeaderContainer}>
              <Header1 className={css.header}>Questions</Header1>
              {Boolean(enableNewSearchExperience) && (
                <a className={css.askQuestionButton} href="/question/ask">
                  Submit a Question
                </a>
              )}
            </div>

            <HomeFeed />
            <ScrollUpButton />
          </div>
          <div className={css.rightColumnSpacer} />
          <div className={css.rightColumn}>
            <ErrorBoundary>
              <div ref={this.programsListRef}>
                {process.env.PROGRAMS_PAGES_RELEASED && (
                  <div className={css.rightList}>
                    <ProgramsList />
                  </div>
                )}
              </div>
            </ErrorBoundary>

            <ErrorBoundary>
              <div
                className={css.rightList}
                style={{
                  maxHeight: `calc(100vh - ${
                    this.state.topicsListTopOffset + 100
                  }px)`,
                  overflow: 'auto',
                }}
              >
                <TopicList />
              </div>
            </ErrorBoundary>
          </div>
        </div>
      </Page>
    )
  }
}

export default withLDConsumer()(HomePage)
