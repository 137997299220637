import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

import { Button } from 'pharmacy/src/input/button'

import { CME_CERTIFICATE_MODAL } from 'mednet-util/src/constants/modal'
import {
  CME_CERTIFICATE_TYPE,
  CME_CERTIFICATE_TYPE_LABEL,
  CME_REDEMPTION_TYPE,
  CME_REDEMPTION_TYPE_LABEL,
} from 'mednet-util/src/constants/cme'
import { mixpanel } from 'mednet-util/src/tracking'

import { useModalDrivers } from 'mednet-cns/src/hooks/modal'
import {
  useCreateCmeCertificate,
  useRedeemedCountsLazy,
  useRedeemedCmeActivitiesLazy,
  useRedeemedMocActivitiesLazy,
} from 'mednet-cns/src/hooks/cme'

import CmeActivitiesTable from 'components/tables/cmeActivitiesTable'
import { CmeCertificateModal } from 'components/cmeModal'

import { CmeRedemptionTypeFilter, CmeYearFilter } from './cmeFilters'
import { MocAvailableActivitiesCard } from './mocAvailableActivitiesCard'

import css from './cmeRedeemedTab.scss'

const useCmeOrMocRedeemedActiviesLazy = (
  filterType,
  filterYear,
  reloadRedeemedCmeActivitiesFlag,
  reloadRedeemedMocActivitiesFlag,
  setReloadRedeemedCmeActivitiesFlag,
  setReloadRedeemedMocActivitiesFlag
) => {
  const [
    redeemedCmeActivities,
    cmeRedeemedActivitiesRequest,
    loadRedeemedCmeActivities,
  ] = useRedeemedCmeActivitiesLazy(filterYear)

  const [
    redeemedMocActivities,
    mocRedeemedActivitiesRequest,
    loadRedeemedMocActivities,
  ] = useRedeemedMocActivitiesLazy(filterYear)

  let redeemedActivities = redeemedCmeActivities
  let redeemedActivitiesRequest = cmeRedeemedActivitiesRequest
  let loadActivities = loadRedeemedCmeActivities
  let reloadFlag = reloadRedeemedCmeActivitiesFlag
  let setReloadFlag = setReloadRedeemedCmeActivitiesFlag

  if (filterType === CME_REDEMPTION_TYPE.MOC) {
    redeemedActivities = redeemedMocActivities
    redeemedActivitiesRequest = mocRedeemedActivitiesRequest
    loadActivities = loadRedeemedMocActivities
    reloadFlag = reloadRedeemedMocActivitiesFlag
    setReloadFlag = setReloadRedeemedMocActivitiesFlag
  }

  const controlledLoadRedeemedActivities = useCallback(() => {
    if (reloadFlag) {
      loadActivities()
      setReloadFlag(false)
    } else if (
      !redeemedActivitiesRequest.isLoaded &&
      !redeemedActivitiesRequest.isLoading
    ) {
      loadActivities()
    }
  }, [
    reloadFlag,
    loadActivities,
    redeemedActivitiesRequest.isLoaded,
    redeemedActivitiesRequest.isLoading,
  ])

  return [
    redeemedActivities,
    redeemedActivitiesRequest,
    controlledLoadRedeemedActivities,
  ]
}

export const CmeRedeemedTab = (props) => {
  const {
    creditsPerActivity,
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    minYear,
    reloadFlag,
    setReloadFlag,
    history,
    maxCreditsPerYear,
    showMocCertificateModal,
  } = props

  const params = new URLSearchParams(history.location.search)
  const redeemMoc = params.get('moc')

  const [filterType, setFilterType] = useState(CME_REDEMPTION_TYPE.CME)
  const [filterYear, setFilterYear] = useState(new Date().getFullYear())

  const [
    { redeemedCmeCount, redeemedMocCount, completedCmeModuleCredits },
    redeemedCountsRequest,
    loadRedeemedCounts,
  ] = useRedeemedCountsLazy(filterYear)

  // These reload flags should stay true(if initial value of reloadFlag is true) until we get the data for the first time
  const [reloadRedeemedCmeActivitiesFlag, setReloadRedeemedCmeActivitiesFlag] =
    useState(reloadFlag)
  const [reloadRedeemedMocActivitiesFlag, setReloadRedeemedMocActivitiesFlag] =
    useState(reloadFlag)
  const [reloadRemaininMocActivitiesFlag, setReloadRemaininMocActivitiesFlag] =
    useState(reloadFlag)

  const [
    redeemedActivities,
    redeemedActivitiesRequest,
    controlledLoadRedeemedActivities,
  ] = useCmeOrMocRedeemedActiviesLazy(
    filterType,
    filterYear,
    reloadRedeemedCmeActivitiesFlag,
    reloadRedeemedMocActivitiesFlag,
    setReloadRedeemedCmeActivitiesFlag,
    setReloadRedeemedMocActivitiesFlag
  )

  const [openCmeCertificateModal] = useModalDrivers(
    CME_CERTIFICATE_MODAL.modalId
  )
  const createCmeCertificate = useCreateCmeCertificate()
  const [selectedRowsIds, setSelectedRowsIds] = useState({})
  const [disabledRowsIds, setDisabledRowsIds] = useState({})
  const [showRemainingMoc, setShowRemainingMoc] = useState(redeemMoc)

  useEffect(() => {
    mixpanel.track('CME Redeemed Tab Load', {})
  }, [])

  useEffect(() => {
    controlledLoadRedeemedActivities() // no need for condition, this is controlled by its own reload flag

    if (reloadFlag) {
      loadRedeemedCounts()
      setReloadFlag(false)
    } else {
      controlledLoadRedeemedActivities() //  no need for condition, this is controlled by its own request status

      if (!redeemedCountsRequest.isLoading && !redeemedCountsRequest.isLoaded) {
        loadRedeemedCounts()
      }
    }
  }, [])

  useEffect(() => {
    controlledLoadRedeemedActivities() //  no need for condition, this is controlled by its own request status

    if (!redeemedCountsRequest.isLoading && !redeemedCountsRequest.isLoaded) {
      loadRedeemedCounts()
    }
  }, [filterYear])

  useEffect(() => {
    controlledLoadRedeemedActivities() //  no need for condition, this is controlled by its own request status
  }, [filterType])

  const updateSelectedRows = useCallback(() => {
    setDisabledRowsIds(
      Object.fromEntries(
        redeemedActivities
          .map((activity, index) => [
            index,
            activity.createdCertificate ? 'Certificate Created' : undefined,
          ])
          .filter(([, value]) => value !== undefined)
      )
    )
  }, [setDisabledRowsIds, redeemedActivities])

  useEffect(() => {
    updateSelectedRows()
  }, [redeemedActivities])

  const getSelectedArray = useCallback(
    (selectedRows) =>
      Object.entries(selectedRows)
        .filter(([_, isSelected]) => isSelected)
        .map(([key, _]) => key),
    []
  )

  const handleSelect = useCallback(
    (updatedSelectedRowsIds) => {
      setSelectedRowsIds(updatedSelectedRowsIds)
    },
    [setSelectedRowsIds]
  )

  const handleSelectAll = useCallback(() => {
    setSelectedRowsIds(
      Object.fromEntries(
        redeemedActivities
          .map((activity, index) => [index, !activity.createdCertificate])
          .filter(([_, notCreatedCertificate]) => notCreatedCertificate)
      )
    )
  }, [setSelectedRowsIds, redeemedActivities])

  const handleDeselectAll = useCallback(() => {
    setSelectedRowsIds({})
  }, [setSelectedRowsIds])

  const handleYearFilterChange = useCallback(
    (selectedOption) => {
      if (selectedOption.value !== filterYear) {
        setSelectedRowsIds({})
        setDisabledRowsIds({})
        setFilterYear(selectedOption.value)
      }
    },
    [setFilterYear, filterYear, setSelectedRowsIds, setDisabledRowsIds]
  )

  const handleTypeFilterChange = useCallback(
    (selectedOption) => {
      if (selectedOption.value !== filterType) {
        setSelectedRowsIds({})
        setDisabledRowsIds({})
        setFilterType(selectedOption.value)
      }
    },
    [setFilterType, filterType, setSelectedRowsIds, setDisabledRowsIds]
  )

  const tableActivities = useMemo(() => {
    return redeemedActivities.map((redeemedCmeActivities, index) => ({
      ...redeemedCmeActivities,
      id: index,
    }))
  }, [redeemedActivities])

  const handleCertificateButtonClick = useCallback(() => {
    const certificateActivitiesIds = redeemedActivities
      .filter((_activity, index) => selectedRowsIds[index])
      .map((activity) => activity.cmeActivityId)

    // Should still show because past MOC credits could be redeemed without the needed profile data
    if (
      certificateToCreateTypes.some(
        (type) =>
          type === CME_CERTIFICATE_TYPE.ABIM_MOC ||
          type === CME_CERTIFICATE_TYPE.ABP_MOC
      )
    ) {
      mixpanel.track('Clicked Create MOC Certificate')
      if (showMocCertificateModal) {
        openCmeCertificateModal({
          certificateActivitiesIds,
          certificateTypes: certificateToCreateTypes,
          history,
        })
      } else {
        mixpanel.track('created_moc_certificate')
        createCmeCertificate(
          { types: certificateToCreateTypes },
          certificateActivitiesIds,
          (res) => {
            if (res.success) {
              history.push('/cme?tab=Certificates')
            }
          }
        )
      }
    } else {
      mixpanel.track('Clicked Create CME Certificate')
      createCmeCertificate(
        { types: [CME_CERTIFICATE_TYPE.CME] },
        certificateActivitiesIds,
        (res) => {
          if (res.success) {
            mixpanel.track('created_cme_certificate')
            history.push('/cme?tab=Certificates')
          }
        }
      )
    }
  }, [
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    redeemedActivities,
    selectedRowsIds,
  ])

  const handleViewRemainingMocButtonClick = useCallback(() => {
    setShowRemainingMoc(true)
    mixpanel.track('Viewed Remaining MOC activities')
  }, [setShowRemainingMoc])

  const certificateToCreateTypes = useMemo(() => {
    return filterType === CME_REDEMPTION_TYPE.CME
      ? [CME_CERTIFICATE_TYPE.CME]
      : isAbimMocEnabledForUser && isAbpMocEnabledForUser // This should never happen but in case we had some users like these in future
      ? [CME_CERTIFICATE_TYPE.ABIM_MOC, CME_CERTIFICATE_TYPE.ABP_MOC]
      : isAbimMocEnabledForUser
      ? [CME_CERTIFICATE_TYPE.ABIM_MOC]
      : [CME_CERTIFICATE_TYPE.ABP_MOC]
  }, [filterType, isAbimMocEnabledForUser, isAbpMocEnabledForUser])

  const createCertificateButtonText = useMemo(() => {
    return `Create ${
      certificateToCreateTypes.length === 1
        ? CME_CERTIFICATE_TYPE_LABEL[certificateToCreateTypes[0]]
        : CME_REDEMPTION_TYPE_LABEL[filterType]
    } certificate${certificateToCreateTypes.length > 1 ? 's' : ''}`
  }, [certificateToCreateTypes, filterType])

  const allowClaimingRemainingMoc = useMemo(
    () =>
      (isAbimMocEnabledForUser || isAbpMocEnabledForUser) &&
      redeemedCountsRequest.isLoaded &&
      redeemedMocCount !== redeemedCmeCount &&
      filterYear === new Date().getFullYear(),
    [
      isAbimMocEnabledForUser,
      isAbpMocEnabledForUser,
      redeemedCountsRequest.isLoaded,
      redeemedMocCount,
      redeemedCmeCount,
      filterYear,
    ]
  )

  if (showRemainingMoc && (isAbimMocEnabledForUser || isAbpMocEnabledForUser)) {
    return (
      <div className={css.mocActivitiesCardContainer}>
        <MocAvailableActivitiesCard
          closeCard={() => setShowRemainingMoc(false)}
          isAbimMocEnabledForUser={isAbimMocEnabledForUser}
          isAbpMocEnabledForUser={isAbpMocEnabledForUser}
          maxCreditsPerYear={maxCreditsPerYear}
          creditsPerActivity={creditsPerActivity}
          redeemedCount={redeemedMocCount}
          reloadFlag={reloadRemaininMocActivitiesFlag}
          setReloadFlag={setReloadRemaininMocActivitiesFlag}
        />
      </div>
    )
  }

  return (
    <div className={css.mainContainer}>
      <div className={css.header}>
        <div className={css.titleMainText}>
          Redeemed CME Credits:{' '}
          <span className={css.credits}>
            {(redeemedCmeCount ?? 0) * creditsPerActivity +
              (completedCmeModuleCredits ?? 0)}
          </span>
        </div>
        <div className={css.mocPointsContainer}>
          {/* This is just to keep the main text in the center  */}
          {allowClaimingRemainingMoc && (
            <div className={css.hiddenMocPointsDiv}></div>
          )}

          {(isAbimMocEnabledForUser || isAbpMocEnabledForUser) && (
            <div className={css.titleMainText}>
              Redeemed MOC Points:{' '}
              <span className={css.credits}>
                {redeemedMocCount * creditsPerActivity}
              </span>
            </div>
          )}

          {allowClaimingRemainingMoc && (
            <div
              className={css.claimMoc}
              onClick={handleViewRemainingMocButtonClick}
            >
              View & Claim Remaining MOC
            </div>
          )}
        </div>
      </div>

      <div className={css.tableHeader}>
        <div className={css.displayForLargeScreen}>
          <Button
            type="dark_blue"
            className={css.button}
            isDisabled={isEmpty(getSelectedArray(selectedRowsIds))}
            onClick={handleCertificateButtonClick}
          >
            <div className={css.buttonText}>{createCertificateButtonText}</div>
          </Button>
        </div>
        <div className={css.filtersContainer}>
          <CmeYearFilter
            minYear={minYear}
            handleFilterChange={handleYearFilterChange}
          />
          <CmeRedemptionTypeFilter
            isAbimMocEnabledForUser={isAbimMocEnabledForUser}
            isAbpMocEnabledForUser={isAbpMocEnabledForUser}
            handleFilterChange={handleTypeFilterChange}
          />
        </div>
      </div>

      <div className={css.tableContainer}>
        <CmeActivitiesTable
          cmeActivities={tableActivities}
          isLoading={redeemedActivitiesRequest.isLoading}
          loadingDataError={redeemedActivitiesRequest.isError}
          selectedRowsIds={selectedRowsIds}
          handleSelect={handleSelect}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          disabledRowsIds={disabledRowsIds}
          emptyDataMessage="You have no redeemed activities in the selected year"
          isRedeemed
        />
      </div>

      <div className={css.tableFooter}>
        <div className={css.selectedCreditsText}>
          <div className={css.credits}>
            {getSelectedArray(selectedRowsIds).length * creditsPerActivity}
            &nbsp;&nbsp;
          </div>
          <div>Credits Selected</div>
        </div>
        <div className={css.displayForSmallScreen}>
          <Button
            type="dark_blue"
            className={css.button}
            isDisabled={isEmpty(getSelectedArray(selectedRowsIds))}
            onClick={handleCertificateButtonClick}
          >
            <div className={css.buttonText}>{createCertificateButtonText}</div>
          </Button>
        </div>
      </div>
      <CmeCertificateModal />
    </div>
  )
}
