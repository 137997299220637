import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Header1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import {
  FETCH_SPECIALTY_SUBSPECIALTIES,
  fetchSpecialtySubspecialties,
  getFetchSpecialtySubspecialtiesRequestId,
} from 'mednet-cns/src/reducers/subspecialty'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { finishOnboarding } from 'mednet-cns/src/reducers/user'

import { mixpanel } from 'mednet-util/src/tracking'

import ToggleUserSubspecialtyButton from './toggleUserSubspecialtyButton'

import css from './customizeFeedScreen.scss'

class CustomizeFeedScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initiallySelectedIds: [],
    }
  }

  componentDidMount() {
    if (!this.props.subspecialtiesLoaded && this.props.specialtyId) {
      this.props.fetchSubspecialties(this.props.specialtyId)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.subspecialtiesLoaded !== this.props.subspecialtiesLoaded &&
      this.props.subspecialtiesLoaded
    ) {
      this.setState({
        initiallySelectedIds: this.props.subspecialties
          ? _.values(this.props.subspecialties)
              .filter((subspecialty) => subspecialty.isAssignedToCurrentUser)
              .map((subspecialty) => subspecialty.subspecialtyId)
          : [],
      })
    }
  }

  handleClose = () => {
    mixpanel.track('selected_onboarding_subspecialties', {
      subspecialties: this.props.userSubspecialtyIds,
    })
    this.props.finishOnboarding()
    this.props.closeModal()
  }

  render() {
    const { subspecialties, subspecialtiesLoaded } = this.props
    if (!subspecialtiesLoaded) {
      return (
        <StarLoader
          wait={false}
          className={css.loading}
          isVerticalMargin={false}
        />
      )
    }

    return (
      <div>
        <Header1>Customize your feed</Header1>
        <div className={css.sectionLinks}>
          {_.orderBy(
            _.values(subspecialties),
            [
              (subspecialty) =>
                this.state.initiallySelectedIds.includes(
                  subspecialty.subspecialtyId
                ),
              'subspecialty',
            ],
            ['desc', 'asc']
          ).map((subspecialty) => (
            <ToggleUserSubspecialtyButton
              key={subspecialty.subspecialtyId}
              subspecialtyId={subspecialty.subspecialtyId}
            />
          ))}
        </div>
        <div className={css.buttonContainer}>
          <Button className={css.continueButton} onClick={this.handleClose}>
            Finish
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const specialtyId = state.user?.data?.specialtyId
  const subspecialtiesRequest =
    _.get(
      state.request.requests,
      makeRequestName(
        FETCH_SPECIALTY_SUBSPECIALTIES,
        getFetchSpecialtySubspecialtiesRequestId(specialtyId, [
          'isAssignedToCurrentUser',
        ])
      )
    ) || {}

  const subspecialties = _.pickBy(
    state.subspecialty?.subspecialties || {},
    (subspecialty) => !subspecialty?.subspecialty?.includes('Tumor Board')
  )
  const userSubspecialtyIds = _.values(subspecialties)
    .filter((subspecialty) => subspecialty.isAssignedToCurrentUser)
    .map((subspecialty) => subspecialty.subspecialtyId)

  return {
    specialtyId,
    subspecialties,
    subspecialtiesLoaded: subspecialtiesRequest.isLoaded,
    userSubspecialtyIds,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubspecialties: (specialtyId) =>
      dispatch(
        fetchSpecialtySubspecialties(specialtyId, ['isAssignedToCurrentUser'])
      ),
    finishOnboarding: () => dispatch(finishOnboarding()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeFeedScreen)
