import React from 'react'

import TabContext from './tabContext'

const createTabsHeader = ({ HeaderContainer, TabContainer }) => {
  return class TabHeader extends React.Component {
    handleTabChange = (onTabChange) => (tabIndex) => {
      onTabChange(tabIndex)
    }

    render() {
      return (
        <TabContext.Consumer>
          {({ onTabChange, activeTab, isInRouter, tabs }) => (
            <HeaderContainer {...this.props}>
              {tabs.map((tab, index) => {
                if (tab.show === false) {
                  return null
                }

                return (
                  <TabContainer
                    key={index}
                    fullWidthTabs={this.props?.fullWidthTabs}
                    tabsCount={tabs.length}
                    {...this.props?.headerTabProps}
                  >
                    {React.cloneElement(tab.header, {
                      active: index === activeTab,
                      tabIndex: index,
                      onTabChange: this.handleTabChange(onTabChange),
                      isInRouter,
                      ...this.props?.headerTabProps?.tabButtonProps,
                    })}
                  </TabContainer>
                )
              })}
            </HeaderContainer>
          )}
        </TabContext.Consumer>
      )
    }
  }
}

export default createTabsHeader
