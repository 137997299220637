import React, { useCallback, useState, useEffect } from 'react'
import { createSelector } from 'reselect'
import classNames from 'classnames'

import { HomeIcon, Logo } from 'pharmacy/src/style/logo'
import { Header } from 'pharmacy/src/navigation/header'
import { Link } from 'pharmacy/src/navigation/link'
import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'
import { Icon } from 'pharmacy/src/display/icon'

import { getRequest } from 'mednet-cns/src/api/v1'
import {
  FETCH_USER_WITH_PERMISSIONS,
  FETCH_USER_WITH_PERMISSIONS_PUBLIC,
} from 'mednet-cns/src/reducers/user'

import { useSelector } from 'react-redux'

import { Tooltip } from 'react-tooltip'

import { useFlags } from 'launchdarkly-react-client-sdk'

import { useIsLDReadyForUser } from 'mednet-cns/src/hooks/launchDarkly'

import { publicRoutes } from 'constants/publicRoutes'
import { ROUTES } from 'constants/routes'
import { PAGES } from 'constants/pages'

import HeaderDropdown from './headerDropdown'
import HeaderSearch from './headerSearch'
import { GatedAskQuestionButton } from './headerAskQuestionButton'
import { HeaderRegisterButton } from './headerRegisterButton'
import HeaderLink from './headerLink'

import css from './anteriorHeader.scss'
import useShowSearchUpdateTooltip from './useShowSearchUpdateTooltip'
import { HeaderLlmSearch } from './headerLlmSearch'

const UPDATED_SEARCH_TOOLTIP_ID = 'updated-search-tooltip'

const AnteriorHeader = (props) => {
  const selectUser = useCallback(
    createSelector(
      (state) => (state.user.data ? state.user.data.userId : undefined),
      (state) => getRequest(state, FETCH_USER_WITH_PERMISSIONS_PUBLIC),
      (state) => getRequest(state, FETCH_USER_WITH_PERMISSIONS),
      (userId, publicUserRequest, userRequest) => {
        return {
          userId,
          isLoading: userRequest.isLoading || publicUserRequest.isLoading,
          isError: userRequest.isError || publicUserRequest.isError,
        }
      }
    )
  )

  const { userId, isLoading, isError } = useSelector(selectUser)
  const isGuest = isLoading || isError || !userId
  const [isGuestMenuVisible, setGuestMenuVisible] = useState(false)
  const [isLoginPageActive, setLoginPageActive] = useState(
    props.history.location.pathname === ROUTES[PAGES.LOGIN].path
  )

  const isLdReady = useIsLDReadyForUser()

  const {
    questionSearchAlgorithm,
    enforceAlgoliaQuestionSearchInAutoComplete,
  } = useFlags()

  const enableNewSearchExperience =
    !enforceAlgoliaQuestionSearchInAutoComplete &&
    questionSearchAlgorithm === 'lux_and_llm'

  const [showUpdatedSearchTooltip, setShowUpdatedSearchTooltip] =
    useShowSearchUpdateTooltip(
      UPDATED_SEARCH_TOOLTIP_ID,
      props.history.location.pathname
    )

  useEffect(() => {
    if (props.history) {
      const unlistenToLocation = props.history.listen(() => {
        setGuestMenuVisible(false)
        setLoginPageActive(
          props.history.location.pathname === ROUTES[PAGES.LOGIN].path
        )
        return unlistenToLocation
      })
    }

    return () => {}
  }, [])
  const renderHomeButton = () => {
    return (
      <div className={css.homeIconContainer}>
        <Link
          external={isLoading} // This is needed to redirect when request is loading but user is logged in
          pathname="/home/index"
          className={css.homeIconLink}
        >
          <HomeIcon className={css.logo} />
          <p>Home</p>
        </Link>
      </div>
    )
  }

  return (
    <Header
      className={css.header}
      history={props.history}
      publicRoutes={publicRoutes}
    >
      <div className={css.logoContainer}>
        <Link
          external={isLoading} // This is needed to redirect when request is loading but user is logged in
          pathname={isGuest ? '/' : '/home/index'}
          className={css.logoLink}
        >
          <Logo className={css.logo} />
        </Link>
      </div>
      {!isGuest && renderHomeButton()}
      {!isLoading && (
        <>
          {!isGuest && isLdReady && (
            <>
              <div
                className={classNames(css.search, {
                  [css.llmSearch]: enableNewSearchExperience,
                })}
                data-tooltip-id={UPDATED_SEARCH_TOOLTIP_ID}
                data-tooltip-variant="info"
              >
                <ErrorBoundary>
                  {!enableNewSearchExperience && <HeaderSearch />}
                  {enableNewSearchExperience && <HeaderLlmSearch />}
                </ErrorBoundary>
              </div>

              <Tooltip
                id={UPDATED_SEARCH_TOOLTIP_ID}
                effect="solid"
                isOpen={showUpdatedSearchTooltip}
                place="bottom"
                opacity={1}
                className={css.searchUpdateTooltip}
              >
                <Icon
                  className={css.searchUpdateTooltipCloseIcon}
                  icon={['far', 'times']}
                  onClick={() => setShowUpdatedSearchTooltip(false)}
                />
                <h1 className={css.searchUpdateTooltipHeader}>
                  Improved Search!
                </h1>
                {/* Should change this text to be more specific to the new search experience */}
                <h3>Begin typing to search our database of Q&A</h3>
              </Tooltip>
            </>
          )}

          <HeaderDropdown
            className={classNames(
              isGuest ? css.guestHeaderDropdown : css.headerDropdown,
              {
                [css.hiddenMenuInSmallScreen]: isGuest && !isGuestMenuVisible,
                [css.transparentMenuInSmallScreen]:
                  !isLdReady || enableNewSearchExperience,
              }
            )}
            isGuest={isGuest}
            history={props.history}
          />

          <div className={css.buttonsContainer}>
            {isGuest ? (
              <>
                <Link pathname={ROUTES[PAGES.LOGIN].path}>
                  <HeaderLink
                    className={classNames(css.signInLink, {
                      [css.activeSignInLink]: isLoginPageActive,
                    })}
                  >
                    Sign In
                  </HeaderLink>
                </Link>

                <HeaderRegisterButton
                  pathname={props.history?.location?.pathname}
                />
                <Icon
                  className={css.menuBars}
                  icon={isGuestMenuVisible ? ['far', 'times'] : ['far', 'bars']}
                  onClick={() => setGuestMenuVisible(!isGuestMenuVisible)}
                />
              </>
            ) : (
              Boolean(isLdReady) &&
              !enableNewSearchExperience && <GatedAskQuestionButton />
            )}
          </div>
        </>
      )}
    </Header>
  )
}

export default AnteriorHeader
