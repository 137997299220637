import React, { useCallback } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

import { CAMPAIGN_REGISTER_MODAL } from 'mednet-util/src/constants/modal'
import { mixpanel } from 'mednet-util/src/tracking'
import { getUserCmeModulePath } from 'mednet-util/src/sponsorship'

import { openModal } from 'mednet-cns/src/reducers/modal'

import { Subtitle1 } from '../../typography'
import { Icon } from '../../display/icon'

import CmeCertificateIcon from '../icons/cmeCertificateIcon'

import classes from './cmeProgramHighlight.scss'

export default function CmeProgramHighlight({
  sponsorship,
  stubbed,
  trackingProps,
  onClose,
}) {
  const dispatch = useDispatch()
  let activityButtonText = 'Resume CME Activity'
  const userStage = sponsorship.cmeModule?.userCmeModule?.userStage

  if (_.isNil(userStage)) {
    activityButtonText = 'Start CME Activity'
  } else if (userStage === 'COMPLETE') {
    activityButtonText = 'Review CME Activity'
  }

  const onButtonClick = useCallback(
    (buttonName) => {
      mixpanel.track(`clicked_${buttonName}_in_question_cme_highlight`, {
        sponsorship_id: sponsorship.sponsorshipId,
        sponsorship_topic: sponsorship.topic,
        sponsorship_public_name: sponsorship.publicName,
        sponsorship_description: sponsorship.description,
        program_type: sponsorship.programType,
        ...trackingProps,
      })

      if (stubbed) {
        dispatch(openModal(CAMPAIGN_REGISTER_MODAL.modalId))
      } else {
        const userCmeModulePath = getUserCmeModulePath(
          sponsorship.sponsorshipId,
          sponsorship.cmeModule?.userCmeModule?.userCmeModuleId,
          userStage
        )

        window.open(userCmeModulePath, '_blank', 'noopener,noreferrer')
      }
    },
    [dispatch, stubbed, userStage, sponsorship]
  )

  return (
    <div className={classes.container}>
      {Boolean(onClose) && (
        <div className={classes.closeButtonContainer} onClick={onClose}>
          <Icon icon={['fal', 'times']} />
        </div>
      )}
      <Subtitle1>Explore this topic to earn more CME credits</Subtitle1>
      <Subtitle1 className={classes.title}>{sponsorship.publicName}</Subtitle1>

      <div className={classes.buttonsContainer}>
        <div>
          <button
            className={classes.earnButton}
            onClick={() => onButtonClick('earn_button')}
          >
            <CmeCertificateIcon size={21} />
            EARN {sponsorship.cmeModule.cmeCredits} CME
          </button>
        </div>

        <div>
          <button
            className={classes.activityButton}
            onClick={() => onButtonClick('activity_button')}
          >
            {activityButtonText}
            <Icon icon={['fas', 'arrow-right']} />
          </button>
        </div>
      </div>
    </div>
  )
}
