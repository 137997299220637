import React from 'react'
import includes from 'lodash/includes'
import get from 'lodash/get'
import * as Sentry from '@sentry/browser'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import {
  smartlookClient,
  mixpanel,
  getMixpanelReferrerProperties,
  setMixpanelAgentProperties,
} from 'mednet-util/src/tracking'
import { openModal } from 'mednet-cns/src/reducers/modal'
import { ONBOARDING_MODAL } from 'mednet-util/src/constants/modal'
import { getRequest, mednetAPI } from 'mednet-cns/src/api/v1'
import {
  fetchUserWithPermissions,
  fetchUserWithPermissionsPublic,
  FETCH_USER_WITH_PERMISSIONS,
  FETCH_USER_WITH_PERMISSIONS_PUBLIC,
  setLaunchDarklyStatus,
} from 'mednet-cns/src/reducers/user'

import { OutdatedBrowserPage } from 'pages/outdatedBrowser'
import { ErrorPage } from 'pages/error'

import { publicRoutes } from '../../constants/publicRoutes'

class Manager extends React.Component {
  constructor(props) {
    super(props)

    if (!props.userIsLoaded) {
      props.fetchUserWithPermissions(this.setUser)
    }
  }

  componentDidMount() {
    if (this.props.userIsLoaded) {
      this.setUser()
    }
  }

  componentDidUpdate(prevProp) {
    if (this.props.userIsLoaded) {
      this.setUser()
    } else if (
      prevProp.fetchUserWithPermissions !== this.props.fetchUserWithPermissions
    ) {
      this.props.fetchUserWithPermissions(this.setUser)
    }
  }

  setUser = () => {
    const { openOnboardingModal, userData, userIsLoaded } = this.props

    if (userIsLoaded && userData) {
      const { userId, specialtyId, onboardingQuestion } = userData

      if (!userId) {
        try {
          if (
            mixpanel.get_distinct_id() &&
            // if mixpanel distinct id is a number, this means it is an id from our system and not anonymous
            !isNaN(mixpanel.get_distinct_id())
          ) {
            const referrerProps = getMixpanelReferrerProperties() // get current referrer props before reset
            // Clears super properties and generates a new random distinct_id for this instance.
            mixpanel.reset()
            mixpanel.register(referrerProps)
            setMixpanelAgentProperties()
          }

          // Add RB2B tracking script
          const scriptId = 'rb2b-script'

          if (
            !process.env.DEBUG &&
            typeof document !== 'undefined' &&
            !document.getElementById(scriptId)
          ) {
            const script = document.createElement('script')
            script.id = scriptId
            script.innerHTML = `!function () {var reb2b = window.reb2b = window.reb2b || [];if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);args.unshift(method);reb2b.push(args);return reb2b;};};for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("0NW1GH7D2ZO4");}();`
            document.body.appendChild(script)
          }
          // eslint-disable-next-line no-empty
        } catch (e) {}
        return
      }

      try {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: userId,
            specialtyId,
          })
        })
        // eslint-disable-next-line no-empty
      } catch (e) {}

      try {
        smartlookClient.identify(userId)
        // eslint-disable-next-line no-empty
      } catch (e) {}

      try {
        mixpanel.identify(userId)
        mixpanel.register('user_id', userId)

        if (!sessionStorage.getItem('updated_mixpanel_profile')) {
          // update mixpanel profiles from client side asynchronously instead of from server side - which had bad effect on loading time
          mednetAPI(`user/user/updateMixpanelProfile`)
          sessionStorage.setItem('updated_mixpanel_profile', true)
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}

      try {
        this.props.ldClient?.identify(
          {
            key: userData?.userId || 'anonymous',
            email: userData?.emailAddress,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            anonymous: !userData?.id,
            custom: {
              specialtyId: userData?.specialtyId,
              expertStatus: userData?.expertStatus,
              specialty: userData?.specialtyName,
            },
          },
          undefined, //hash
          // onDone function
          (err, _flags) => {
            if (!err) {
              this.props.setLaunchDarklyStatus({
                loaded: true,
              })
            } else {
              this.props.setLaunchDarklyStatus({
                error: true,
              })
            }
          }
        )
      } catch (e) {
        console.error(e)
      }

      if (onboardingQuestion) {
        openOnboardingModal()
      }
    }
  }

  render() {
    const { children, userErrors } = this.props

    if (get(window, 'navigator.userAgent', '').match(/MSIE [^10]/g)) {
      return <OutdatedBrowserPage />
    }

    if (userErrors) {
      return <ErrorPage />
    }

    return children
  }
}

const mapStateToProps = (state, ownProps) => {
  let userRequest = undefined

  if (
    !ownProps.location.pathname ||
    ownProps.location.pathname === '/' ||
    publicRoutes.some((publicPath) =>
      includes(
        ownProps.location.pathname.toLowerCase(),
        publicPath.toLowerCase()
      )
    )
  ) {
    userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS_PUBLIC)
  } else {
    userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)
  }

  return {
    userData: state.user.data,
    userErrors: userRequest.isError === true,
    userIsLoaded: userRequest.isLoaded,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  if (
    !ownProps.location.pathname ||
    ownProps.location.pathname === '/' ||
    publicRoutes.some((publicPath) =>
      includes(
        ownProps.location.pathname.toLowerCase(),
        publicPath.toLowerCase()
      )
    )
  ) {
    return {
      fetchUserWithPermissions: (callback) =>
        dispatch(fetchUserWithPermissionsPublic(callback)),
      setLaunchDarklyStatus: (status) =>
        dispatch(setLaunchDarklyStatus(status)),
    }
  }

  return {
    fetchUserWithPermissions: (callback) =>
      dispatch(fetchUserWithPermissions(callback)),
    openOnboardingModal: () => dispatch(openModal(ONBOARDING_MODAL.modalId)),
    setLaunchDarklyStatus: (status) => dispatch(setLaunchDarklyStatus(status)),
  }
}

export default withLDConsumer()(
  compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Manager)
)
