import _ from 'lodash'
import QueryString from 'qs'

import {
  CME_MODULE_STATUS,
  PROGRAM_PUBLISH_STATUS,
  USER_CME_MODULE_USER_STAGE,
} from './constants/sponsorship'

export const getLatestPublishedSponsorship = (sponsorships) => {
  const publishedSponsorships = sponsorships?.filter(
    (sponsorship) =>
      sponsorship.publishedToProgramsCollection ===
        PROGRAM_PUBLISH_STATUS.PUBLISHED && !_.isEmpty(sponsorship.publicName)
  )

  return publishedSponsorships?.length
    ? _.orderBy(publishedSponsorships, ['endDate'], ['desc'])[0]
    : undefined
}

export const isCmeAvailableForSponsorship = (sponsorship) => {
  if (
    sponsorship.cmeModule &&
    sponsorship.cmeModule.cmeModuleStatus === CME_MODULE_STATUS.AVAILABLE_STATUS
  ) {
    const now = new Date()
    const startDate = new Date(sponsorship.startDate)
    const endDate = new Date(sponsorship.endDate)

    if (now > startDate && now < endDate) {
      return true
    }
  }

  return false
}

export const getLatestPublishedNotCompletedCmeSponsorship = (sponsorships) => {
  const filteredSponsorships = sponsorships?.filter(
    (sponsorship) =>
      sponsorship.publishedToProgramsCollection ===
        PROGRAM_PUBLISH_STATUS.PUBLISHED &&
      !_.isEmpty(sponsorship.publicName) &&
      isCmeAvailableForSponsorship(sponsorship) &&
      sponsorship?.cmeModule?.userCmeModule?.userStage !== 'COMPLETE'
  )

  return filteredSponsorships?.length
    ? _.orderBy(filteredSponsorships, ['endDate'], ['desc'])[0]
    : undefined
}

export const getSponsorshipStoryModePath = (
  sponsorshipId,
  questionId = null
) => {
  if (questionId) {
    return `/v2/programs/${sponsorshipId}/questions?mode=question&questionId=${questionId}`
  }

  return `/v2/programs/${sponsorshipId}`
}

export const getProgramCollectionPath = (programType, filters) => {
  if (!programType) {
    return `/v2/programs`
  }

  return `/v2/programs/collection?${QueryString.stringify(
    {
      programType,
      ...filters,
    },
    { indices: false }
  )}`
}

export const getUserCmeModulePath = (
  sponsorshipId,
  userCmeModuleId = undefined,
  userStage = undefined
) => {
  let discussionUrl = `/v2/programs/${sponsorshipId}/userCmeModule`

  if (userCmeModuleId) {
    discussionUrl += `/${userCmeModuleId}`
    if (userStage) {
      const pageName =
        userStage === USER_CME_MODULE_USER_STAGE.COMPLETE
          ? USER_CME_MODULE_USER_STAGE.REDEEM_CME
          : userStage
      discussionUrl += `/${_.camelCase(pageName)}`
    }
  }

  return discussionUrl
}
