import { all } from 'redux-saga/effects'
import { connectRouter } from 'connected-react-router'

import {
  reducer as user,
  rootSaga as userSaga,
} from 'mednet-cns/src/reducers/user'
import {
  reducer as userCV,
  rootSaga as userCVSaga,
} from 'mednet-cns/src/reducers/userCV'
import {
  reducer as feed,
  rootSaga as feedSaga,
} from 'mednet-cns/src/reducers/feed'
import {
  reducer as jobPreference,
  rootSaga as jobPreferenceSaga,
} from 'mednet-cns/src/reducers/jobPreference'
import {
  reducer as question,
  rootSaga as questionSaga,
} from 'mednet-cns/src/reducers/question'
import {
  reducer as topic,
  rootSaga as topicSaga,
} from 'mednet-cns/src/reducers/topic'
import {
  reducer as answer,
  rootSaga as answerSaga,
} from 'mednet-cns/src/reducers/answer'
import {
  reducer as poll,
  rootSaga as pollSaga,
} from 'mednet-cns/src/reducers/poll'
import {
  reducer as survey,
  rootSaga as surveySaga,
} from 'mednet-cns/src/reducers/survey'
import {
  reducer as digest,
  rootSaga as digestSaga,
} from 'mednet-cns/src/reducers/digest'
import {
  reducer as expertEmail,
  rootSaga as expertEmailSaga,
} from 'mednet-cns/src/reducers/expertEmail'
import {
  reducer as mention,
  rootSaga as mentionSaga,
} from 'mednet-cns/src/reducers/mention'
import {
  reducer as institution,
  rootSaga as institutionSaga,
} from 'mednet-cns/src/reducers/institution'
import {
  reducer as specialty,
  rootSaga as specialtySaga,
} from 'mednet-cns/src/reducers/specialty'
import {
  reducer as sponsorship,
  rootSaga as sponsorshipSaga,
} from 'mednet-cns/src/reducers/sponsorship'
import {
  reducer as sponsor,
  rootSaga as sponsorSaga,
} from 'mednet-cns/src/reducers/sponsor'
import {
  reducer as subspecialty,
  rootSaga as subspecialtySaga,
} from 'mednet-cns/src/reducers/subspecialty'
import {
  reducer as subspecialtyTopic,
  rootSaga as subspecialtyTopicSaga,
} from 'mednet-cns/src/reducers/subspecialtyTopic'
import {
  reducer as questionUpdate,
  rootSaga as questionUpdateSaga,
} from 'mednet-cns/src/reducers/questionUpdate'
import {
  reducer as tumorBoard,
  rootSaga as tumorBoardSaga,
} from 'mednet-cns/src/reducers/tumorBoard'
import {
  reducer as expert,
  rootSaga as expertSaga,
} from 'mednet-cns/src/reducers/expert'
import {
  reducer as editor,
  rootSaga as editorSaga,
} from 'mednet-cns/src/reducers/editor'
import {
  reducer as preset,
  rootSaga as presetSaga,
} from 'mednet-cns/src/reducers/preset'
import {
  reducer as systemJob,
  rootSaga as systemJobSaga,
} from 'mednet-cns/src/reducers/systemJob'
import {
  reducer as campaign,
  rootSaga as campaignSaga,
} from 'mednet-cns/src/reducers/campaign'
import { rootSaga as contactSaga } from 'mednet-cns/src/reducers/contact'
import {
  reducer as cme,
  rootSaga as cmeSaga,
} from 'mednet-cns/src/reducers/cme'
import {
  reducer as publication,
  rootSaga as publicationSaga,
} from 'mednet-cns/src/reducers/publication'
import {
  reducer as testimonial,
  rootSaga as testimonialSaga,
} from 'mednet-cns/src/reducers/testimonial'
import {
  reducer as announcement,
  rootSaga as announcementSaga,
} from 'mednet-cns/src/reducers/announcment'
import {
  reducer as pressRelease,
  rootSaga as pressReleaseSaga,
} from 'mednet-cns/src/reducers/pressRelease'
import {
  reducer as curriculum,
  rootSaga as curriculumSaga,
} from 'mednet-cns/src/reducers/curriculum'
import {
  reducer as journal,
  rootSaga as journalSaga,
} from 'mednet-cns/src/reducers/journal'
import {
  reducer as registration,
  rootSaga as registrationSaga,
} from 'mednet-cns/src/reducers/registration'

import { reducer as request } from 'mednet-cns/src/reducers/request'
import { reducer as modal } from 'mednet-cns/src/reducers/modal'
import { reducer as popup } from 'mednet-cns/src/reducers/popup'
import { reducer as banner } from 'mednet-cns/src/reducers/banner'

export function* rootSaga() {
  yield all([
    answerSaga(),
    digestSaga(),
    expertEmailSaga(),
    feedSaga(),
    institutionSaga(),
    jobPreferenceSaga(),
    mentionSaga(),
    pollSaga(),
    questionSaga(),
    questionUpdateSaga(),
    specialtySaga(),
    sponsorSaga(),
    sponsorshipSaga(),
    subspecialtySaga(),
    subspecialtyTopicSaga(),
    surveySaga(),
    topicSaga(),
    userCVSaga(),
    userSaga(),
    tumorBoardSaga(),
    expertSaga(),
    editorSaga(),
    contactSaga(),
    presetSaga(),
    systemJobSaga(),
    campaignSaga(),
    cmeSaga(),
    publicationSaga(),
    testimonialSaga(),
    announcementSaga(),
    pressReleaseSaga(),
    curriculumSaga(),
    journalSaga(),
    registrationSaga(),
  ])
}

export const reducers =
  (history) =>
  (state = {}, action) => ({
    answer: answer(state.answer, action, state),
    banner: banner(state.banner, action, state),
    digest: digest(state.digest, action, state),
    expertEmail: expertEmail(state.expertEmail, action, state),
    feed: feed(state.feed, action, state),
    institution: institution(state.institution, action, state),
    jobPreference: jobPreference(state.jobPreference, action, state),
    mention: mention(state.mention, action, state),
    modal: modal(state.modal, action, state),
    poll: poll(state.poll, action, state),
    popup: popup(state.popup, action, state),
    question: question(state.question, action, state),
    questionUpdate: questionUpdate(state.questionUpdate, action, state),
    request: request(state.request, action, state),
    router: connectRouter(history)(state.router, action),
    specialty: specialty(state.specialty, action, state),
    sponsor: sponsor(state.sponsor, action, state),
    sponsorship: sponsorship(state.sponsorship, action, state),
    subspecialty: subspecialty(state.subspecialty, action, state),
    subspecialtyTopic: subspecialtyTopic(
      state.subspecialtyTopic,
      action,
      state
    ),
    survey: survey(state.survey, action, state),
    topic: topic(state.topic, action, state),
    user: user(state.user, action, state),
    userCV: userCV(state.userCV, action, state),
    tumorBoard: tumorBoard(state.tumorBoard, action, state),
    expert: expert(state.expert, action, state),
    editor: editor(state.editor, action, state),
    preset: preset(state.preset, action, state),
    systemJob: systemJob(state.systemJob, action, state),
    campaign: campaign(state.campaign, action, state),
    cme: cme(state.cme, action, state),
    publication: publication(state.publication, action, state),
    testimonial: testimonial(state.testimonial, action, state),
    announcement: announcement(state.announcement, action, state),
    pressRelease: pressRelease(state.pressRelease, action, state),
    curriculum: curriculum(state.curriculum, action, state),
    journal: journal(state.journal, action, state),
    registration: registration(state.registration, action, state),
  })
